function lookup_vehicle_load(data){
  var vehicle_types = adminPanel.abelKit._admin.get('contracts').getSettingForSelectedContract('vehicleTypes');

  if (data.vehicle !== undefined) {
    for (i = 0; i < vehicle_types.length; i++) {
      if (data.vehicle.VehicleTypeId == vehicle_types[i].typeId) {
        vehicle_types[i].selected_vehicle_type = true;
      } else {
        vehicle_types[i].selected_vehicle_type = false;
      }
    }
    data.vehicle_types = vehicle_types;
  }

  $("#content").html(Templates['lookup_vehicle'](data));
  resetLinkListener();

  //Cath enter in typeahead
  $("#lookup_vehicle_form").submit(function(e) {
    lookup_vehicle_search('');
    return false;
  });

  //Do typeahead
  $("#vehicle_search").bind('change paste keyup',function() {
      lookup_vehicle_search('');
  });

  //Update vehicle
  $("#vehicle_form").submit(function(e) {
    if (!$('#vehicle_id').is( "input[type='hidden']" )) {
      //add
      $.ajaxWrapper(
        'Vehicle/', //resource
        'POST', //type
        {Vehicle: {
          LicencePlate: $('#vehicle_id').val().trim(), VehicleTypeId: $('#vehicle_type_id').val()}
        }, //data,
        false, //notification
        {
          success: function(data){
            document.location.hash = 'lookup_vehicle/' + $('#vehicle_id').val();
            $.bootstrapGrowl('Added.', {'delay':2000, 'type':'success'});
          }
        } //ajax options
      );
    } else {
      //update
      $.ajaxWrapper(
        'Vehicle/'+$('#vehicle_id').val(), //resource
        'POST', //type
        {Vehicle: {
          VehicleTypeId: $('#vehicle_type_id').val()
        }}, //data,
        false, //notification
        {
          success: function(data){
            $.bootstrapGrowl('Saved.', {'delay':2000, 'type':'success'});
          }
        } //ajax options
      );
    }
    return false;
  });
  return true;
}

function lookup_vehicle_remove(id) {
  if (confirm("Are you sure?") == true) {
    $.ajaxWrapper(
      'Vehicle/'+id+'/Remove', //resource
      'POST', //type
      {}, //data,
      false, //notification
      {
        success: function(data){
          document.location.hash = 'lookup_vehicle';
          $.bootstrapGrowl('Removed.', {'delay':2000, 'type':'success'});
        }
      } //ajax options
    );
  } else {
    $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
  }
}

function lookup_vehicle_new() {
  lookup_vehicle_load({vehicle:{new:true}});
}

function lookup_vehicle_search(){
  $.ajaxWrapper(
    'Vehicle/Search/'+$('#vehicle_search').val(), //resource
    'GET', //type
    {}, //data,
    false, //notification
    {
      success: function(data){
        //Unhide result table
        $("#searchtable").html(Templates['lookup_vehicle_searchtable']({vehicles: data.return}));
        resetLinkListener();
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        $('#searchtable').innerHTML = '';
      }
    } //ajax options
  );
}

function lookup_vehicle_lookup(vehicle_id){
  $.ajaxWrapper(
    'Vehicle/'+vehicle_id, //resource
    'GET', //type
    {}, //data,
    true, //notification
    {
      success: function(data){
        //Unhide result table
        lookup_vehicle_load({vehicle:data.return.vehicle, recentShifts:data.return.recent_shifts});
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        lookup_vehicle_load({});
      }
    } //ajax options
  );
}
