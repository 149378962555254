function updateTemplate() {
    const contracts      = adminPanel.abelKit._admin.get('contracts');
    const activeContract = contracts.getActive();

    // set menu items based on contract
    if ((activeContract.rights & 1) == 1) $('#navbar_menu_map').removeClass('hidden');
    if ((activeContract.rights & 4) == 4 || (activeContract.rights & 8192) == 8192) $('#navbar_menu_lookup').removeClass('hidden');
    if ((activeContract.rights & 16) == 16 || (activeContract.rights & 32) == 32 || (activeContract.rights & 64) == 64 || (activeContract.rights & 128) == 128 || (activeContract.rights & 512) == 512) $('#navbar_menu_reporting').removeClass('hidden');
    if ((activeContract.rights & 1048576) == 1048576) $('#navbar_menu_scheduler').removeClass('hidden');

    // set username
    $('#user_name').html(adminPanel.abelKit._admin.get().name);

    // set contract selector
    $("#navbar_menu_contract").html(Templates['contracts_selector']({
        contracts        : contracts.data,
        selectedContract : activeContract
    }));

    // set appropriate logo
    $("#header_logo").html(Templates['header_logo']({
        settings: activeContract.settings,
    }));
}

//AJAX wrapper function
jQuery.ajaxWrapper = function(resource, type, data, notification, ajaxOptions) {
    var alert;
    var token = false;
    if (type == 'POST') {
        var data = JSON.stringify(data);
        var contenttype = 'application/json';
        var processData = true;
        var requestType = type;
    } else if (type == 'formData') {
        var data = data;
        var contenttype = false;
        var processData = false;
        var requestType = 'POST';
    } else {
        var data = data;
        var contenttype = 'application/x-www-form-urlencoded; charset=UTF-8'; //is actually only true for POST requests, but this is default, see http://api.jquery.com/jquery.ajax/
        var processData = true;
        var requestType = type;
    }
    adminPanel.abelKit._auth.getValidToken(function (token) {
        $.ajax($.extend({
            type: requestType,
            contentType: contenttype,
            url: adminPanel.abelKit.getServer()+'AbelAdminService/'+resource,
            dataType: "json",
            data: data,
            processData: processData,
            beforeSend: function(jqXHR, settings){
                //jqXHR.setRequestHeader('X-TIMEZONE', moment().format('Z')); //Doesn't work when requesting times from summer time when in winter time or vice versa
                jqXHR.setRequestHeader('X-TIMEZONE', adminPanel.abelKit._admin.get('timezone'));
                //jqXHR.setRequestHeader('X-TIMEZONE', 'CET');
                jqXHR.setRequestHeader('Authorization', 'Bearer ' + token);
                jqXHR.setRequestHeader('Api-Version', adminPanel.abelKit.apiVersion);
                jqXHR.setRequestHeader('X-CONTRACTID', adminPanel.abelKit._admin.get('contracts').getActive().id);
                if (notification) {
                    alert = $.bootstrapGrowl("Loading...");
                }
            },
            complete: function(jqXHR, textStatus){
                if (notification) {
                    alert.alert("close");
                }

                if (textStatus != 'success') {
                    try {
                        var error_data = $.parseJSON(jqXHR.responseText);
                        $.bootstrapGrowl(error_data.result.ErrorText + ' (' + error_data.result.ErrorCode + ')', {'delay':2000, 'type':'danger'});
                    } catch (e) {
                        $.bootstrapGrowl('Connection ' + textStatus, {'delay':2000, 'type':'danger'});
                    }
                }
            }
        }, ajaxOptions));
    }, function (error) {
        $.bootstrapGrowl('Auth error: ' + error.message, {'delay':2000, 'type':'danger'});
        console.log('Auth error: ' + error.message);
    });
};

//Bind an event to window.onhashchange that, when the hash changes, gets the hash and adds the class "selected" to any matching nav link.
$(window).hashchange( function(){
    var hash = location.hash.slice(1);
    var hash_split = hash.split("/");

    menu_item = hash_split[0].split('_');

    //Highlight right menu item
    $("li[id^='navbar_menu_']").removeClass('active');
    $('#navbar_menu_'+menu_item[0]).addClass('active');
    $('#collapse_navbar').collapse('hide'); //Default

    //Load container based on menu selection and try to highlight correct submenu-iten
    var allowed_containers = ['map','lookup','scheduler', 'reporting','settings'];
    if (allowed_containers.indexOf(menu_item[0]) != -1) {
        $("#content_container").html(Templates[menu_item[0] + '_container']({
            user: adminPanel.abelKit._admin.get(),
            contracts: adminPanel.abelKit._admin.get('contracts').data,
            selectedContract: adminPanel.abelKit._admin.get('contracts').getActive()
        }));
        $("li[id^='submenu_']").removeClass('active');
        $("li[id^='subsubmenu_']").removeClass('active');
        if (menu_item.length > 0) $('#submenu_' + menu_item[1]).addClass('active');
        if (menu_item.length > 1) $('#subsubmenu_' + menu_item[1] + '_' + menu_item[2]).addClass('active');
        setSubContainer(hash_split);
        resetLinkListener();
    } else {
        //Default: reload
        if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 4) == 4) {
            document.location.hash = 'lookup_customer';
        } else if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 1) == 1) {
            document.location.hash = 'map';
        } else if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 16) == 16) {
            document.location.hash = 'reporting_clients';
        } else if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 32) == 32) {
            document.location.hash = 'reporting_ops_graph';
        } else if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 64) == 64) {
            document.location.hash = 'reporting_finance';
        } else if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 128) == 128 || (adminPanel.abelKit._admin.get('contracts').getActive().rights  & 512) == 512) {
            document.location.hash = 'reporting_query';
        } else if ((adminPanel.abelKit._admin.get('contracts').getActive().rights) & 256 == 256) {
            document.location.hash = 'settings_user';
        } else {
            document.location.hash = 'settings_profile';
            //$.bootstrapGrowl('Cannot find stuff you have access to', {'delay':2000, 'type':'danger'});
        }
    }
});

function setSubContainer(hash_split) {
    //Stop loaders
    menu_item = hash_split[0].split('_');

    if (typeof map_action == 'function') {
        map_action('stop');
    }

    //Add content to #content, specified in the container
    if (hash_split[0] == 'lookup_customer') {
        lookup_customer_load({});
        if (hash_split.length == 2) {
            if (hash_split[1] == 'add') {
                lookup_customer_load({customer:{new:true, enabled: true}});
            } else {
                lookup_customer_lookup(hash_split[1]);
            }
        } else if (hash_split.length === 3) {
            // Search for customer
            lookup_customer_search(decodeURIComponent(hash_split[2]));
        } else if (hash_split.length === 5 && hash_split[2] === 'attributes' && hash_split[4] === 'remove') {
            // Remove customer attribute
            lookup_customer_remove_attribute(hash_split[1], hash_split[3]);
        } else {
            lookup_customer_search('');
        }
    } else if (hash_split[0] == 'lookup_trip') {
        lookup_trip_load({});
        if (hash_split.length > 1) {
            if (hash_split[1] == 'add') {
                if (hash_split.length == 3) {
                    lookup_trip_load_new(hash_split[2]);
                } else {
                    lookup_trip_load_new('');
                }
            } else if (hash_split.length >= 3) {
                lookup_trip_search(decodeURIComponent(hash_split[2]));
            } else {
                lookup_trip_lookup(hash_split[1]);
            }
        } else {
            lookup_trip_search('');
        }
    } else if (hash_split[0] == 'lookup_activity') {
        lookup_load(menu_item[0], 'activity',{});
        if (hash_split.length > 1) {
            if (hash_split.length >= 3) {
                lookup_search(menu_item[0], 'activity',decodeURIComponent(hash_split[2]));
            } else {
                lookup_item(menu_item[0], 'activity',hash_split[1]);
            }
        } else {
            lookup_search(menu_item[0], 'activity');
        }
    } else if (hash_split[0] == 'lookup_debuglog') {
        lookup_load(menu_item[0], 'debuglog',{});
        if (hash_split.length > 1) {
            if (hash_split.length >= 3) {
                lookup_search(menu_item[0], 'debuglog',decodeURIComponent(hash_split[2]));
            } else {
                lookup_item(menu_item[0], 'debuglog',hash_split[1]);
            }
        } else {
            lookup_search(menu_item[0], 'debuglog');
        }
    } else if (hash_split[0] == 'settings_user') {
        lookup_load(menu_item[0], 'user',{});
        if (hash_split.length == 2) {
            if (hash_split[1] == 'add') {
                lookup_item_add(menu_item[0], 'user');
            } else {
                lookup_item(menu_item[0], 'user',hash_split[1]);
            }
        } else if (hash_split.length == 3) {
            lookup_search(menu_item[0], 'user',decodeURIComponent(hash_split[2]));
        } else {
            lookup_search(menu_item[0], 'user');
        }
    } else if (hash_split[0] == 'lookup_tripstate') {
        lookup_load(menu_item[0], 'tripstate',{});
        if (hash_split.length > 2) {
            lookup_search(menu_item[0], 'tripstate',decodeURIComponent(hash_split[2]));
        } else {
            lookup_search(menu_item[0], 'tripstate');
        }
    } else if (hash_split[0] == 'lookup_paymentmethod') {
        lookup_load(menu_item[0], 'paymentmethod',{});
        if (hash_split.length > 2) {
            lookup_search(menu_item[0], 'paymentmethod',decodeURIComponent(hash_split[2]));
        } else {
            lookup_search(menu_item[0], 'paymentmethod');
        }
    } else if (hash_split[0] == 'lookup_reconciliation_invoice') {
        lookup_load(menu_item[0], 'reconciliation_invoice',{});
        if (hash_split[1] == 'search') {
            lookup_search(menu_item[0], 'reconciliation_invoice',decodeURIComponent(hash_split[2]), false);
        } else if (hash_split.length == 2) {
            lookup_item(menu_item[0], 'reconciliation_invoice',decodeURIComponent(hash_split[1]));
        } else {
            startOfYesterday = moment().tz(adminPanel.abelKit._admin.get('timezone')).startOf('day').subtract(15,'day').format('Y-M-D HH:mm:ss');
            lookup_search(menu_item[0], 'reconciliation_invoice', 'status:open before:"' + startOfYesterday + '"', false);
        }
    } else if (hash_split[0] == 'lookup_reconciliation_batch') {
        lookup_load(menu_item[0], 'reconciliation_batch',{});
        if (hash_split[1] == 'search') {
            lookup_search(menu_item[0], 'reconciliation_batch',decodeURIComponent(hash_split[2]), false);
        } else {
            lookup_search(menu_item[0], 'reconciliation_batch', '', false);
        }
    } else if (hash_split[0] == 'lookup_reconciliation_payment') {
        lookup_load(menu_item[0], 'reconciliation_payment',{});
        if (hash_split[1] == 'search') {
            lookup_search(menu_item[0], 'reconciliation_payment',decodeURIComponent(hash_split[2]), false);
        } else if (hash_split.length > 2) {
            lookup_item(menu_item[0], 'reconciliation_payment',decodeURIComponent(hash_split[1]) + '/' + decodeURIComponent(hash_split[2]));
        } else {
            startOfYesterday = moment().tz(adminPanel.abelKit._admin.get('timezone')).startOf('day').subtract(15,'day').format('Y-M-D HH:mm:ss');
            lookup_search(menu_item[0], 'reconciliation_payment', 'status:open', false);
        }
    } else if (hash_split[0] == 'lookup_reconciliation_upload') {
        lookup_load(menu_item[0], 'reconciliation_upload',{item: 'generic'});
    } else if (hash_split[0] == 'lookup_servicenotification') {
        lookup_load(menu_item[0], 'servicenotification',{});
        if (hash_split[1] == 'search') {
            lookup_search(menu_item[0], 'servicenotification',decodeURIComponent(hash_split[2]), false);
        } else if (hash_split[1] == 'add') {
            lookup_item_add(menu_item[0], 'servicenotification');
        } else if (hash_split.length == 2) {
            lookup_item(menu_item[0], 'servicenotification',hash_split[1]);
        } else {
            lookup_search(menu_item[0], 'servicenotification', 'status:open', false);
        }
    } else if (hash_split[0] == 'lookup_driver') {
        lookup_driver_load({});
        if (hash_split.length == 2) {
            if (hash_split[1] == 'add') {
                lookup_driver_new();
            } else {
                lookup_driver_lookup(hash_split[1]);
            }
        } else if (hash_split.length == 3) {
            lookup_driver_search(decodeURIComponent(hash_split[2]));
        } else {
            lookup_driver_search('');
        }
    } else if (hash_split[0] == 'lookup_vehicle') {
        lookup_vehicle_load({});
        if (hash_split.length == 2) {
            if (hash_split[1] == 'add') {
                lookup_vehicle_new();
            } else {
                lookup_vehicle_lookup(hash_split[1]);
            }
        } else if (hash_split.length == 3) {
            lookup_vehicle_search(decodeURIComponent(hash_split[2]));
        } else {
            lookup_vehicle_search('');
        }
    } else if (hash_split[0] == 'lookup_discount') {
        lookup_discount_load({});
        if (hash_split.length == 2) {
            if (hash_split[1] == 'add') {
                lookup_discount_new();
            } else {
                lookup_discount_lookup(hash_split[1]);
            }
        } else if (hash_split.length == 3) {
            lookup_discount_search(decodeURIComponent(hash_split[2]));
        } else {
            lookup_discount_search('');
        }
    } else if (hash_split[0] == 'lookup_company') {
        lookup_company_load({});
        if (hash_split.length == 2) {
            if (hash_split[1] == 'add') {
                lookup_company_new();
            } else {
                lookup_company_lookup(hash_split[1]);
            }
        } else if (hash_split.length == 3) {
            lookup_company_search(decodeURIComponent(hash_split[2]));
        } else {
            lookup_company_search('');
        }
    } else if (hash_split[0] == 'lookup_promocode') {
        lookup_promocode_load({});
        if (hash_split.length == 2) {
            if (hash_split[1] == 'add') {
                lookup_promocode_new();
            } else {
                lookup_promocode_lookup(hash_split[1]);
            }
        } else if (hash_split.length == 3) {
            lookup_promocode_search(decodeURIComponent(hash_split[2]));
        } else {
            lookup_promocode_search('');
        }
    } else if (hash_split[0] == 'reporting_clients') {
        reporting_graphs_load('clients');
    } else if (hash_split[0] == 'reporting_ops_graph') {
        reporting_graphs_load('ops_graph');
    } else if (hash_split[0] == 'reporting_ops_table') {
        reporting_graphs_load('ops_table');
    } else if (hash_split[0] == 'reporting_finance') {
        reporting_graphs_load('finance');
    } else if (hash_split[0] == 'reporting_query') {
        reporting_query_load();
    } else if (hash_split[0] == 'map') {
        map_load();
    } else if (hash_split[0] == 'scheduler') {
        adminPanel.scheduler();
    } else if (hash_split[0] == 'settings_profile') {
        lookup_item(menu_item[0], 'profile',false);
    } else if (hash_split[0] == 'settings_subscriptions') {
        lookup_item(menu_item[0], 'subscriptions',false);
    } else if (hash_split[0] == 'settings_apps') {
        lookup_item(menu_item[0], 'apps',false);
    } else if (hash_split[0] == 'settings_bi') {
        lookup_item(menu_item[0], 'bi', false);
    } else if (hash_split[0] == 'settings_meetingpoints') {
        $("#content").html(Templates['settings_meetingpoints']({}));
        resetLinkListener();
        adminPanel.meetingPoints();
    } else if (hash_split[0] == 'settings_mail') {
        lookup_item(menu_item[0], 'mail', false);
    } else if (hash_split[0] == 'settings_payments') {
        lookup_item(menu_item[0], 'payments', false);
    } else if (hash_split[0] == 'settings_templates') {
        if (hash_split.length == 2) {
            lookup_item(menu_item[0], 'templates', hash_split[1]);
        } else {
            document.location.hash = 'settings_templates/changeEmailEn';
        }
    } else if (hash_split[0] == 'reporting') {
        if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 128) == 128 || (adminPanel.abelKit._admin.get('contracts').getActive().rights  & 512) == 512) {
            document.location.hash = 'reporting_query';
        } else if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 16) == 16) {
            document.location.hash = 'reporting_clients';
        } else if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 32) == 32) {
            document.location.hash = 'reporting_ops_graph';
        } else if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 64) == 64) {
            document.location.hash = 'reporting_finance';
        } else {
            $.bootstrapGrowl('Cannot find stuff you have access to', {'delay':2000, 'type':'danger'});
        }
    } else if (hash_split[0] == 'lookup') {
        if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 4) == 4) {
            document.location.hash = 'lookup_customer';
        } else if ((adminPanel.abelKit._admin.get('contracts').getActive().rights & 8192) == 8192) {
            document.location.hash = 'lookup_reconciliation_invoice';
        } else {
            $.bootstrapGrowl('Cannot find stuff you have access to', {'delay':2000, 'type':'danger'});
        }
    } else if (hash_split[0] == 'settings') {
        document.location.hash = 'settings_profile';
    }

    $(document).trigger("hashchanged");
}

function resetLinkListener() {
    /*
    Listens to a-href clicks and notifies document that hashchange has been completed
    */
    $("a").unbind('.dashboardLinkListener');
    $("a").on( "click.dashboardLinkListener", function(event) {
        //Try to reload when hash doesn't change
        var hash_old = location.hash.slice(1);
        var self = $(this);
        var link = self.attr('href');
        if (link != undefined) {
            var hash_new = link.substring(link.indexOf('#')+1);
            if (hash_old == hash_new) {
                $(window).hashchange();
            }
        }
        $(this).unbind(event);
    });


}


//Format number
function number_format(number,decimals,show_sign) {
    var decimal_sep = '.';
    var thousand_sep = ',';
    if(!show_sign) {
        number = Math.abs(number);
    } else {
        number = number*1;
    }
    number = number.toFixed(decimals) + '';
    var x = number.split('.');
    var x1 = x[0];
    var x2 = x.length > 1 ? decimal_sep + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + thousand_sep + '$2');
    }
    return x1 + x2;
}


//Typeahead function: clean input
function escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
}

//Check whether email adres conforms to spec
function isValidEmailAddress(emailAddress) {
    //thanks to http://stackoverflow.com/questions/2855865/jquery-regex-validation-of-e-mail-address
    var pattern = new RegExp(/^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i);

    //Return bool
    return pattern.test(emailAddress);
};


//Check whether parameter is in the GET query string
function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
    return false;
};

//Format shortest time format
function shorter_time_format(dt, show_time) {
    //d should be a moment
    if (!moment.isMoment(dt)) return dt;
    if (show_time) {
        shorter_format_time = "HH:mm";
        shorter_format_datetime = "D MMM HH:mm";
        shorter_format_year = "D MMM [']YY";
    } else {
        shorter_format_time = "[today]";
        shorter_format_datetime = "D MMM";
        shorter_format_year = "D MMM [']YY";
    }
    if (dt > moment().startOf('day') && dt < moment().endOf('day')) {
        return dt.format(shorter_format_time);
    } else if (dt > moment().startOf('year') && dt < moment().endOf('year')) {
        return dt.format(shorter_format_datetime);
    } else {
        return dt.format(shorter_format_year);
    }
}

function short_time_format(dt, show_time) {
    //dt should be a moment
    if (!moment.isMoment(dt)) return dt;
    if (show_time) {
        shorter_format_time = "HH:mm:ss";
        shorter_format_datetime = "D MMM HH:mm:ss";
        shorter_format_year = "D MMM [']YY HH:mm:ss";
    } else {
        shorter_format_time = "today";
        shorter_format_datetime = "D MMM";
        shorter_format_year = "D MMM [']YY";
    }
    if (dt > moment().startOf('day') && dt < moment().endOf('day')) {
        return dt.format(shorter_format_time);
    } else if (dt > moment().startOf('year') && dt < moment().endOf('year')) {
        return dt.format(shorter_format_datetime);
    } else {
        return dt.format(shorter_format_year);
    }
}

//Generate link to export table as CSV
function create_csv_link(tableselector) {
    //Build contents
    reporting_query_csv = [];

    var rowvals = []
    $(tableselector+' tr').each(function (i, row) {
        var cellvals = []
        var $row = $(row).find("th,td").each(function(i, cell) {
            cellvals.push('"'+$(cell).text().replace(/"/g,'""')+'"');
        });
        rowvals.push(cellvals.join(','));
    });

    // download stuff
    var fileName = "sql_export.csv";
    var buffer = rowvals.join("\n");
    var blob = new Blob([buffer], {
        "type": "text/csv;charset=utf8;"
    });
    var link = document.createElement("a");

    if(link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        link.setAttribute("href", window.URL.createObjectURL(blob));
        link.setAttribute("download", fileName);
        return link;
    } else if(navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, fileName);
        return link;
    } else {
        //no link
        return false;
    }

}

String.prototype.underscoreToPathCase = function() {
    string = this;
    string = string.replace(/[\-_\s]+(.)?/g, function(match, chr) {
        return chr ? '/' + chr.toUpperCase() : '';
    });
    // Ensure 1st char is always uppercase
    return string.substr(0, 1).toUpperCase() + string.substr(1);
};

$.fn.serializeObject = function()
{
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};
