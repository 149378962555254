var reporting_query_csv =[];

function reporting_query_load(query, server){
  $('#filter_div').addClass('hidden');
  if (typeof query === 'undefined') {
    var sql = 'SELECT TABLE_NAME FROM INFORMATION_SCHEMA.Tables';
  } else {
    var sql = query;
  }
  if (typeof server === 'undefined' || server != 'bi') {
    $('#query_radio_backend').prop('checked', true);
  } else {
    $('#query_radio_bi').prop('checked', true);
  }
  $("#content").html(Templates['reporting_query']({query: sql, user: adminPanel.abelKit._admin.get(), contracts: adminPanel.abelKit._admin.get('contracts').data, selectedContract: adminPanel.abelKit._admin.get('contracts').getActive()}));
  resetLinkListener();
  return true;
}

function reporting_query_submit(){
  query = $('#queryTextArea').val();
  if ($('#query_radio_backend').prop('checked')) {
    server = 'Backend';
  } else {
    server = 'Bi';
  }
  $.ajaxWrapper(
      'Query/'+server, //resource
      'POST', //type
      {Query: query}, //data,
      true, //notification
      {
        success: function(data){
          //Unhide result table
          returnhtml = reporting_query_generate_table(data.return);
          $('#queryReturnTable').html(returnhtml);
          var csv_link = create_csv_link('#queryReturnTable');
          csv_link.innerHTML = "(CSV)";
          $('#query_csv_export').html(csv_link);
          $('#queryReturnDiv').removeClass('hidden');
          //$.bootstrapSortable();
          $.bootstrapSortable({ sign: "arrow reversed" });
        },
        error: function(xhr, errorType, exception) {
          //Hide result table
          $('#queryReturnDiv').addClass('hidden');
        }
      } //ajax options
    );
}


function reporting_query_generate_table(data) {
  //Create html-table

  //All elements have same header
  if ($.isArray(data) && data.length > 0) {
      returnhtml = '<thead><tr>';
      $.each(data[0], function(key, value) {
        returnhtml += '<th>'+key+'</th>';
      });
      returnhtml += '</tr></thead><tbody>';
      for (var i = 0 ; i < data.length ; i++) {
        returnhtml += '<tr>';
        $.each(data[i], function(key, value) {
          returnhtml += '<td>'+value+'</td>';
        });
        returnhtml += '</tr>';
      }
      returnhtml += '</tbody>';
      return returnhtml;
  } else {
      return '';
  }
}
