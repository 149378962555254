var driver_croppie_set = false;

function lookup_driver_load(data){
  data.random = Math.random();
  $("#content").html(Templates['lookup_driver'](data));
  resetLinkListener();

  //Catch enter in typeahead
  $("#lookup_driver_form").submit(function(e) {
    //lookup_driver_search();
    document.location.hash = 'lookup_driver/search/' + encodeURIComponent($('#driver_search').val());

    return false;
  });

  //Do typeahead
  /*$("#driver_search").bind('change paste keyup',function() {
      lookup_driver_search();
  });*/

  //On upload of avatar

  //Show image
  $('#driver_photo').change(function() {
    var photo = $('#driver_photo')[0].files[0];
    if (typeof photo !== 'undefined') {
      //Remove existing avatar and/or existing croppy from DOM if available
      $('#driver_avatar').remove();
      $('#driver_croppie').croppie('destroy');

      //photo selected
      var FR = new FileReader();
      FR.onload = function(e) {
        $('#driver_croppie').croppie({
          viewport: {
              width: 640,
              height: 640,
              type: 'square' //default 'square'
          },
          boundary: {
              width: 700,
              height: 700
          }
        });
        $('#driver_croppie').croppie('bind', {url:e.target.result});
        driver_croppie_set = true;
      }
      FR.readAsDataURL(photo);
    }
  });

  //Update or add driver
  $("#driver_form").submit(function(e) {
    var enabled = false;
    if ($('#driver_enabled').prop('checked')) {
      enabled = true;
    }

    var data = {'Driver': {
      'FirstName': $('#driver_first_name').val().trim(),
      'LastName': $('#driver_last_name').val().trim(),
      'Tel': $('#driver_phone').val().trim(),
      'Tel2': $('#driver_phone2').val().trim(),
      'Email': $('#driver_email').val().trim(),
      'DriverCardId': $('#driver_card_id').val().trim(),
      'CompanyId': $('#driver_company').val().trim()
    }};

    if (typeof $('#driver_id').val() === 'undefined') {
      data.Password = $('#driver_password').val(); //Password required
    } else {
      if ($('#driver_password').val() != '') {
          data.Password = $('#driver_password').val();
      }
      data.Enabled = enabled;
    }

    if (driver_croppie_set) {
      $('#driver_croppie').croppie('result', {type:'canvas', size: 'viewport', format: 'jpg', quality: 0.8}).then(function (img) {
        data.Avatar = img.substr(img.indexOf(',') + 1);; //Only show part after data:image/jpeg;base64,
        lookup_driver_replace(data);
      });
    } else {
      //No croppie or no image
      lookup_driver_replace(data);
    }

    return false;
  });
  return true;
}

function lookup_driver_replace(data) {
  //add or insert
  if (typeof $('#driver_id').val() === 'undefined') {
    $.ajaxWrapper(
      'Driver/', //resource
      'POST', //type
      data, //data,
      false, //notification
      {
        success: function(data){
          //console.log(data);
          document.location.hash = 'lookup_driver/' + data.result.Driver.DriverId;
          driver_croppie_set = false;
          $.bootstrapGrowl('Saved.', {'delay':2000, 'type':'success'});
        }
      } //ajax options
    );
  } else {
    //update
    $.ajaxWrapper(
      'Driver/'+$('#driver_id').val(), //resource
      'POST', //type
      data, //data,
      false, //notification
      {
        success: function(data){
          $.bootstrapGrowl('Saved.', {'delay':2000, 'type':'success'});
          driver_croppie_set = false;
          lookup_driver_lookup($('#driver_id').val());
        }
      } //ajax options
    );
  }
}

function lookup_driver_remove(id) {
  if (confirm("Are you sure?") == true) {
    $.ajaxWrapper(
      'Driver/'+id+'/Remove', //resource
      'POST', //type
      {}, //data,
      false, //notification
      {
        success: function(data){
          document.location.hash = 'lookup_driver';
          $.bootstrapGrowl('Removed.', {'delay':2000, 'type':'success'});
        }
      } //ajax options
    );
  } else {
    $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
  }
}

function lookup_driver_new() {
  //TODO: this should be a autocomplete, as right now you can only add drivers to the last 50 companies
  $.ajaxWrapper(
    'Company/Search/', //resource
    'GET', //type
    {}, //data,
    true, //notification
    {
      success: function(data){
        lookup_driver_load({driver:{new:true}, companies:data.return});
      }
    } //ajax options
  );
}


function lookup_driver_search(query){
  if (typeof query !== 'undefined') {
    $('#driver_search').val(query);
  }
  $.ajaxWrapper(
    'Driver/Search/'+encodeURI($('#driver_search').val()), //resource
    'GET', //type
    {}, //data,
    false, //notification
    {
      success: function(data){
        //Unhide result table
        $("#searchtable").html(Templates['lookup_driver_searchtable']({drivers: data.return}));
        resetLinkListener();
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        $('#searchtable').innerHTML = '';
      }
    } //ajax options
  );
}

function lookup_driver_lookup(driver_id){
  $.ajaxWrapper(
    'Driver/'+driver_id, //resource
    'GET', //type
    {}, //data,
    true, //notification
    {
      success: function(data){
        for (i = 0; i < data.return.companies.length; i++) {
          if (data.return.companies[i].NCOMPANYID == data.return.driver.CompanyId) {
            data.return.companies[i].selected_company = true;
          } else {
            data.return.companies[i].selected_company = false;
          }
        }
        lookup_driver_load({driver:data.return.driver, recentShifts:data.return.recent_shifts, companies:data.return.companies, middleware_server: adminPanel.abelKit.getServer()});
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        lookup_driver_load({});
      }
    } //ajax options
  );
}

function lookup_driver_change_state(driverid,action) {
  if (confirm("Are you sure?") == true) {
    $.ajaxWrapper(
        'Driver/'+driverid+'/'+action, //resource
        'POST', //type
        {}, //data,
        true, //notification
        {
          success: function(data){
            if (data.return == 'ActiveWithIntentToDeactivate') {
              $.bootstrapGrowl('Will be logged out when he finishes his last trip. New trips will not be assigned.', {'delay':2000, 'type':'success'});
            } else {
              $.bootstrapGrowl('Done.', {'delay':2000, 'type':'success'});
            }
            $(window).hashchange();
          }
        } //ajax options
      );
  } else {
    $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
  }
}

function lookup_driver_action(driverid, action) {
  if (confirm("Are you sure?") == true) {
    $.ajaxWrapper(
        'Driver/'+driverid+'/'+action, //resource
        'POST', //type
        {}, //data,
        true, //notification
        {
          success: function(data){
            $.bootstrapGrowl('Done.', {'delay':2000, 'type':'success'});
          }
        } //ajax options
      );
  } else {
    $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
  }
}
