var mapMarkers = [];
var waiting_on_driver_max = 120; //in seconds. After this, sound the alarm

function loadMap(elementId) {
    untouchMarkers();
    removeUntouchedMarkers();

    // initialize map
    const map = new google.maps.Map(
        document.getElementById(elementId),
        {
            disableDefaultUI: true,
            zoomControl: true,
        }
    );

    // reset markers
    const mapMarkers = [];

    // add zoom listener
    map.addListener(
        'zoom_changed',
        function() {
            for (var i = 0; i < mapMarkers.length; i++) {
                mapMarkers[i].marker.setIcon(getMarkerIcon(mapMarkers[i].marker.markup, map.getZoom()));
            }
        }
    );

    return map;
}

function addMarker(map, markerId, latLng, toolTip, markup) {
  //This function requires that you first add all drivers (before the customers), to get the passenger count in the cars right

  var retval = {};

  //If passenger in car, determine offset
  if (markup.type == 'client' && markup.state == 'transit' ) {
    markup.passenger_offset = 0; //Failsafe, if no vehicle is found
    for (var i = 0; i < mapMarkers.length; i++) {
      if (mapMarkers[i].marker.markup.type == 'driver' && mapMarkers[i].marker.markup.id == markup.driver) {
        markup.passenger_offset = mapMarkers[i].marker.markup.passengers;
        mapMarkers[i].marker.markup.passengers = mapMarkers[i].marker.markup.passengers + markup.passengers;
      }
    }
  } else if (markup.type == 'driver') {
    //So first add a driver, then its passengers
    markup.passengers = 0;
  }

  //Find marker
  marker = getMarker(markerId);

  if (marker != null) {
        if (markup.type == 'client' && marker.markup.on_time == true && markup.on_time == false) {
            retval['changed_to_late'] = true;
        }

        if (markup.type == 'client' && marker.markup.time_waiting <= waiting_on_driver_max && markup.time_waiting > waiting_on_driver_max) {
            retval['waiting_too_long'] = true;
        }

        const markerLong = marker.getPosition().lng();
        const markerLat = marker.getPosition().lat();

        // update position
        if (Math.abs(latLng.lat - markerLat) > 1e-5 || Math.abs(latLng.lng - markerLong) > 1e-5) {
            // marker moved; animate it
            fps = 36;
            duration = 2;

            stepLat = (latLng.lat - markerLat) / fps / duration;
            stepLng = (latLng.lng - markerLong) / fps / duration;

            moveMarker(marker, fps, 0, fps * duration, stepLat, stepLng, latLng);
        }

    //Update icon
    marker.setIcon(getMarkerIcon(markup, map.getZoom()));
    marker.markup = markup;
    marker.touched = true;

    //Update toolTip
    //marker.setTooltipContent(toolTip);

  } else {
    if (markup.type == 'driver') {
      retval['new_driver'] = true;
      zIndex = 1;
    } else {
      retval['new_client'] = true;
      if (markup.on_time == false) retval['changed_to_late'] = true;
      if (markup.time_waiting > waiting_on_driver_max) retval['waiting_too_long'] = true;
      zIndex = 2; //customers before cars: higher values displaying in front of markers with lower values
    }

      const marker = new google.maps.Marker({
          position: latLng,
          icon: getMarkerIcon(markup, map.getZoom()),
          title: toolTip,
          // description: point.Description,
          touched: true,
          zIndexOffset: zIndex,
          location: new google.maps.LatLng(latLng.lat, latLng.lng),
          map: map,
      });

    marker.markup = markup;
    mapMarkers.push({ id: markerId, marker: marker });
  }

  return retval;
}

function untouchMarkers() {
  for (var i = 0; i < mapMarkers.length; i++) {
    mapMarkers[i].marker.touched = false;
  }
}

function removeUntouchedMarkers() {
  for (var i = mapMarkers.length-1; i>=0; i--) {
    if(mapMarkers[i].marker.touched == false) {
      if (mapMarkers[i].marker != null) {
        mapMarkers[i].marker.setMap(null);
        mapMarkers.splice(i,1);
      }
    }
  }
}

function getMarker(markerId) {
  for (var i = 0; i < mapMarkers.length; i++) {
    if (mapMarkers[i].id == markerId) {
      return mapMarkers[i].marker;
    }
  }
  return null;
}

function removeMarker(markerId) {
  for (var i = mapMarkers.length-1; 0; i--) {
    if (mapMarkers[i].id == markerId) {
      if (mapMarkers[i].marker != null) {
        mapMarkers[i].marker.setMap(null);
        mapMarkers.splice(i,1);
      }
    }
  }
}

/**
 * Move a certain marker to a certain position.
 *
 * @param marker
 * @param fps
 * @param step
 * @param totalSteps
 * @param stepLat
 * @param stepLng
 * @param finalLatLng
 */
function moveMarker(marker, fps, step, totalSteps, stepLat, stepLng, finalLatLng) {
    if (step < totalSteps) {
        const position = new google.maps.LatLng(
            marker.getPosition().lat() + stepLat,
            marker.getPosition().lng() + stepLng
        );

        marker.setPosition(position);

        setTimeout(function() {
            moveMarker(marker,fps, step + 1, totalSteps, stepLat, stepLng, finalLatLng);
        }, 1000 / fps);
    } else {
        const position = new google.maps.LatLng(finalLatLng.lat, finalLatLng.lng);

        marker.setPosition(position);
    }
}

function mapFittingBounds(LatLngList) {
    const bounds = new google.maps.LatLngBounds();

    minLat = LatLngList[0].lat;
    maxLat = LatLngList[0].lat;
    minLng = LatLngList[0].lng;
    maxLng = LatLngList[0].lng;

    $.each(LatLngList, function(key, LatLng) {
        if (LatLng.lat < minLat) {minLat = LatLng.lat;}
        if (LatLng.lat > maxLat) {maxLat = LatLng.lat;}
        if (LatLng.lng < minLng) {minLng = LatLng.lng;}
        if (LatLng.lng > maxLng) {maxLng = LatLng.lng;}
    });

    const lowerBound = new google.maps.LatLng(minLat, minLng);
    const upperBound = new google.maps.LatLng(maxLat, maxLng);

    bounds.extend(lowerBound);
    bounds.extend(upperBound);

    return bounds;
}

/**
 * Get the appropriate marker icon.
 *
 * @param markup
 * @param zoomLevel
 *
 * @return {{url: string, size: google.maps.Size, origin: Point, anchor: Point}}
 */
function getMarkerIcon(markup, zoomLevel) {
    var iconUrl = 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
    var size = [20, 32];
    var anchor = [0, 0];

    switch (markup.type) {
        case 'driver':
            // Driver icon
            iconUrl = 'img/mapKiaSoul.png';
            size = [46, 28];
            break;
        case 'client':
            // Client icon
            const maxZoomLevel = 10;
            const zoom = markup.state === 'origin' ? (1 - 0.7 * (maxZoomLevel - zoomLevel) / maxZoomLevel) : 0.375;
            var iconOnTime = '';

            anchor = markup.state === 'origin' ?
                [(16 * markup.passengers + 2 * (markup.passengers - 1)) * zoom / 2, 40 * zoom] :
                [0 * zoom - (18 * markup.passenger_offset * zoom), -15 * zoom];

            size = [(16 * markup.passengers + 2 * (markup.passengers - 1)) * zoom, 40 * zoom];

            if (markup.time_waiting > waiting_on_driver_max) {
                iconOnTime = '_long';
            } else if (!markup.on_time) {
                iconOnTime = '_late';
            }

            iconUrl = 'img/mapClient_'+ markup.passengers + iconOnTime + '.png';
    }

    return {
        url: iconUrl,
        scaledSize: new google.maps.Size(size[0], size[1]),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(anchor[0], anchor[1])
    };
}
