//Table specific helper functions
  var reporting_graphs = [];

  //Clients
    reporting_graphs['clients'] = {
      series: [],
      grouping: 'days',
      load: function (data, grouping, start, end) {
        this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
        this.grouping = grouping;
      },
      seriesNames: ['Registered clients', 'Active clients'],
      seriesRowFormatter: function (row) {
        return [moment(row.period_start).unix(),row.registrationsCumulative,row.activationsCumulative];
      },
      xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
      yAxisFormatter: function (val) {return val.toFixed(0);},
      graphType: function () {
        //should return clusteredBar, stackedBar or line
        if (this.series[0].length <= 15) {
          return 'clusteredBar';
        } else {
          return 'line';
        }
      }
    };

  //Client mutations
    reporting_graphs['clients_mutations'] = {
      series: [],
      grouping: 'days',
      load: function (data, grouping, start, end) {
        this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
        this.grouping = grouping;
      },
      seriesNames: ['Registrations', 'Activated clients', 'Deativated clients'],
      seriesRowFormatter: function (row) {
        return [moment(row.period_start).unix(),row.registrations,row.activations, row.deactivations];
      },
      xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
      yAxisFormatter: function (val) {return val.toFixed(0);},
      graphType: function () {
        //should return clusteredBar, stackedBar or line
        if (this.series[0].length <= 15) {
          return 'clusteredBar';
        } else {
          return 'line';
        }
      }
    };

  //Trips
    reporting_graphs['completed_trips'] = {
      series: [],
      grouping: 'days',
      load: function (data, grouping, start, end) {
        this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
        this.grouping = grouping;
      },
      seriesNames: ['Completed trips'],
      seriesRowFormatter: function (row) {
        return [moment(row.period_start).unix(),row.completedTrips];
      },
      xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
      yAxisFormatter: function (val) {return val.toFixed(0);},
      graphType: function () {
        //should return clusteredBar, stackedBar or line
        if (this.series[0].length <= 15) {
          return 'clusteredBar';
        } else {
          return 'line';
        }
      }
    };

  //Active drivers
    reporting_graphs['drivers_active'] = {
      series: [],
      grouping: 'days',
      load: function (data, grouping, start, end) {
        this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
        this.grouping = grouping;
      },
      seriesNames: ['Drivers active [#]'],
      seriesRowFormatter: function (row) {
        return [moment(row.period_start).unix(),row.minutes_active/60/(moment(row.period_end).unix()-moment(row.period_start).unix())*3600];
      },
      xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
      yAxisFormatter: function (val) {return val.toFixed(2);},
      graphType: function () {
        //should return clusteredBar, stackedBar or line
        if (this.series[0].length <= 15) {
          return 'clusteredBar';
        } else {
          return 'line';
        }
      }
    };

  //Trips per driver hour [#]
    reporting_graphs['trips_and_shiftminutes'] = {
      series: [],
      grouping: 'days',
      load: function (data, grouping, start, end) {
        this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
        this.grouping = grouping;
      },
      seriesNames: ['Trips/Driver hour [#]'],
      seriesRowFormatter: function (row) {
        if (row.minutes_active == 0) {
          val = NaN
        } else {
          val = row.completed_trips/row.minutes_active*60;
        }
        return [moment(row.period_start).unix(),val];
      },
      xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
      yAxisFormatter: function (val) {return val.toFixed(1);},
      graphType: function () {
        //should return clusteredBar, stackedBar or line
        if (this.series[0].length <= 15) {
          return 'clusteredBar';
        } else {
          return 'line';
        }
      }
    };

  //Gross revenue per driver hour
      reporting_graphs['tripfare_and_shiftminutes'] = {
        series: [],
        grouping: 'days',
        load: function (data, grouping, start, end) {
          this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
          this.grouping = grouping;
        },
        //seriesNames: ['Gross fare/Driver hour [' + adminPanel.abelKit._admin.get('contracts').getSettingForSelectedContract('primaryCurrency') + ']'],
        seriesNames: ['Gross fare/Driver hour'], //TODO: add currency
        seriesRowFormatter: function (row) {
          if (row.minutes_active == 0) {
            val = NaN
          } else {
            val = row.gross_fare_completed_trips/row.minutes_active*60;
          }
          return [moment(row.period_start).unix(),val];
        },
        xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
        yAxisFormatter: function (val) {return val.toFixed(2);},
        graphType: function () {
          //should return clusteredBar, stackedBar or line
          if (this.series[0].length <= 15) {
            return 'clusteredBar';
          } else {
            return 'line';
          }
        }
      };

    //Utilization
      reporting_graphs['utilization'] = {
        series: [],
        grouping: 'days',
        load: function (data, grouping, start, end) {
          this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
          this.grouping = grouping;
        },
        seriesNames: ['Utilization (seats filled/hour) [#]'],
        seriesRowFormatter: function (row) {
          if (row.successful_trip_count == 0) {
            val = NaN
          } else {
            val = row.successful_trip_passenger_load/row.minutes_active;
          }
          return [moment(row.period_start).unix(),val];
        },
        xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
        yAxisFormatter: function (val) {return val.toFixed(1);},
        graphType: function () {
          //should return clusteredBar, stackedBar or line
          if (this.series[0].length <= 15) {
            return 'clusteredBar';
          } else {
            return 'line';
          }
        }
      };

    //Driver rejects
      reporting_graphs['driver_rejects'] = {
        series: [],
        grouping: 'days',
        load: function (data, grouping, start, end) {
          this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
          this.grouping = grouping;
        },
        seriesNames: ['Rejections by drivers [% of offered rides]'],
        seriesRowFormatter: function (row) {
          if (row.accept_count + row.reject_count == 0) {
            val = NaN
          } else {
            val = row.reject_count/(row.accept_count+row.reject_count);
          }
          return [moment(row.period_start).unix(),val];
        },
        xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
        yAxisFormatter: function (val) {return (val*100).toFixed(0)+'%';},
        graphType: function () {
          //should return clusteredBar, stackedBar or line
          if (this.series[0].length <= 15) {
            return 'clusteredBar';
          } else {
            return 'line';
          }
        }
      };

  //Trips by state [%]
    reporting_graphs['tripstatus_split'] = {
      series: [],
      grouping: 'days',
      load: function (data, grouping, start, end) {
        this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
        this.grouping = grouping;
      },
      seriesNames: [
        'creditCardFailed',
        'driverCancelledBecauseOfDriver',
        'customerArrived',
        'tripDeclined',
        'customerCancelled',
        'driverCancelledBecauseOfClient'
      ],
      seriesRowFormatter: function (row) {
        var total = row.creditCardFailed + row.driverCancelledBecauseOfDriver + row.customerArrived+ row.tripDeclined + row.customerCancelled+ row.driverCancelledBecauseOfClient;
        if (total > 0) {
          return [
            moment(row.period_start).unix(),
            row.creditCardFailed/total,
            row.driverCancelledBecauseOfDriver/total,
            row.customerArrived/total,
            row.tripDeclined/total,
            row.customerCancelled/total,
            row.driverCancelledBecauseOfClient/total
          ];
        } else {
          return [moment(row.period_start).unix(), NaN, NaN, NaN, NaN, NaN, NaN]
        }
      },
      xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
      yAxisFormatter: function (val) {return (val*100).toFixed(0)+'%';},
      graphType: function () {
        //should return clusteredBar, stackedBar or line
        if (this.series[0].length <= 15) {
          return 'clusteredBar';
        } else {
          return 'line';
        }
      }
    };

  //Acceptance times by drivers [sec]
    reporting_graphs['acceptance_time'] = {
      series: [],
      grouping: 'days',
      load: function (data, grouping, start, end) {
        this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
        this.grouping = grouping;
      },
      seriesNames: ['Average', 'P10', 'P90'],
      seriesRowFormatter: function (row) {
        if (row.population == 0) {
          var react_avg = NaN;
        } else {
          var react_avg = row.react_avg;
        }
        if (row.population < 10) {
          var react_p1 = NaN;
          var react_p9 = NaN;
        } else {
          var react_p1 = row.react_p1;
          var react_p9 = row.react_p9;
        }
        return [moment(row.period_start).unix(), react_avg, react_p1, react_p9]
      },
      xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
      yAxisFormatter: function (val) {return (val).toFixed(0);},
      graphType: function () {
        //should return clusteredBar, stackedBar or line
        if (this.series[0].length <= 15) {
          return 'clusteredBar';
        } else {
          return 'line';
        }
      }
    };

  //Fullfillment of pick-up window [%]
    reporting_graphs['punctuality_pickup'] = {
      series: [],
      grouping: 'days',
      load: function (data, grouping, start, end) {
        this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
        this.grouping = grouping;
      },
      seriesNames: ['On time'],
      seriesRowFormatter: function (row) {
        var total = row.on_time_pickup + row.late_pickup;
        if (total > 0) {
          return [
            moment(row.period_start).unix(),
            row.on_time_pickup/total
          ];
        } else {
          return [moment(row.period_start).unix(), NaN]
        }
      },
      xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
      yAxisFormatter: function (val) {return (val*100).toFixed(0)+'%';},
      graphType: function () {
        //should return clusteredBar, stackedBar or line
        if (this.series[0].length <= 15) {
          return 'clusteredBar';
        } else {
          return 'line';
        }
      }
    };

  //Fullfillment of delivery window [%]
    reporting_graphs['punctuality_delivery'] = {
      series: [],
      grouping: 'days',
      load: function (data, grouping, start, end) {
        this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
        this.grouping = grouping;
      },
      seriesNames: ['On time'],
      seriesRowFormatter: function (row) {
        var total = row.on_time_delivery + row.late_delivery;
        if (total > 0) {
          return [
            moment(row.period_start).unix(),
            row.on_time_delivery/total
          ];
        } else {
          return [moment(row.period_start).unix(), NaN]
        }
      },
      xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
      yAxisFormatter: function (val) {return (val*100).toFixed(0)+'%';},
      graphType: function () {
        //should return clusteredBar, stackedBar or line
        if (this.series[0].length <= 15) {
          return 'clusteredBar';
        } else {
          return 'line';
        }
      }
    };

  //Fullfillment of  delivery window [%]
    reporting_graphs['trip_rating'] = {
      series: [],
      grouping: 'days',
      load: function (data, grouping, start, end) {
        this.series = graph_make_time_grid_and_fill_with_data(data, grouping, start, end, this.seriesRowFormatter, this.seriesNames.length);
        this.grouping = grouping;
      },
      seriesNames: ['Average'],
      seriesRowFormatter: function (row) {
        if (row.rated_trip_count > 0) {
          return [
            moment(row.period_start).unix(),
            row.rated_trip_avg_rating
          ];
        } else {
          return [moment(row.period_start).unix(), NaN]
        }
      },
      xAxisFormatter: function () {return graph_time_xaxis_for_grouping(this.grouping)},
      yAxisFormatter: function (val) {return (val).toFixed(2);},
      graphType: function () {
        //should return clusteredBar, stackedBar or line
        if (this.series[0].length <= 15) {
          return 'clusteredBar';
        } else {
          return 'line';
        }
      }
    };
