Handlebars.registerHelper({
    /*
      See http://stackoverflow.com/questions/8853396/logical-operator-in-a-handlebars-js-if-conditional
      With this, we can do:

      {{#if (or section1 section2)}}
      .. content
      {{/if}}

      or

      {{#if (or
              (eq section1 "foo")
              (ne section2 "bar"))}}
      .. content
      {{/if}}
    */

    eq: function (v1, v2) {
        return v1 === v2;
    },
    ne: function (v1, v2) {
        return v1 !== v2;
    },
    lt: function (v1, v2) {
        return v1 < v2;
    },
    gt: function (v1, v2) {
        return v1 > v2;
    },
    lte: function (v1, v2) {
        return v1 <= v2;
    },
    gte: function (v1, v2) {
        return v1 >= v2;
    },
    and: function (v1, v2) {
        return v1 && v2;
    },
    length: function (v1) {
        return v1.length;
    },
    contains: function (array, string) {
        if (!(array instanceof Array)) return false;
        return (array.indexOf(string) != -1);
    },
    has: function (rights, right) {
        return ((rights & right) == right);
    },
    or: function (v1, v2) {
        return v1 || v2;
    },
    index_of: function(obj,key) {
      return obj[key];
    },
    short_datetime: function (v1) {
      if (v1 != undefined && v1 != false) {
          parsed_moment = moment.utc(v1).tz(adminPanel.abelKit._admin.get('timezone'));
        if (parsed_moment == false) {
          return '';
        } else {
          return shorter_time_format(parsed_moment, true);
        }
      } else {
        return '';
      }
    },
    short_date: function (v1) {
      if (v1 != undefined && v1 != false) {
        parsed_moment = moment.utc(v1).tz(adminPanel.abelKit._admin.get('timezone'));
        if (parsed_moment == false) {
          return '';
        } else {
          return shorter_time_format(parsed_moment, false);
        }
      } else {
        return '';
      }
    },
    long_time: function (v1) {
      if (v1 != undefined && v1 != false) {
        parsed_moment = moment.utc(v1).tz(adminPanel.abelKit._admin.get('timezone'));
        if (parsed_moment == false) {
          return '';
        } else {
          return parsed_moment.format("HH:mm:ss");
        }
      } else {
        return '';
      }
    },
    long_datetime: function (v1) {
      if (v1 != undefined && v1 != false) {
        parsed_moment = moment.utc(v1).tz(adminPanel.abelKit._admin.get('timezone'));
        if (parsed_moment == false) {
          return '';
        } else {
          return parsed_moment.format("HH:mm:ss on D MMM YYYY");
        }
      } else {
        return '';
      }
    },
    date_format: function (v1, format) {
      if (v1 != undefined && v1 != false) {
        parsed_moment = moment.utc(v1).tz(adminPanel.abelKit._admin.get('timezone'));
        if (parsed_moment == false) {
          return '';
        } else {
          return parsed_moment.format(format);
        }
      } else {
        return '';
      }
    },
    date: function (v1) {
      if (v1 != undefined && v1 != false) {
        parsed_moment = moment.utc(v1).tz(adminPanel.abelKit._admin.get('timezone'));
        if (parsed_moment == false) {
          return '';
        } else {
          return parsed_moment.format("YYYY-MM-DD");
        }
      } else {
        return '';
      }
    },
    full_datetime: function (v1) {
      if (v1 != undefined && v1 != false) {
        parsed_moment = moment.utc(v1).tz(adminPanel.abelKit._admin.get('timezone'));
        if (parsed_moment == false) {
          return '';
        } else {
          return short_time_format(parsed_moment, true);
        }
      } else {
        return '';
      }
    },
    obj_to_string: function (v1) {
      try {
          return JSON.stringify(v1, null, 2);
      } catch (e) {
          return '';
      }
    },
    number_format: function (number, decimals) {
      return number_format(number, decimals, true);
    },
    setting: function (setting) {
      return adminPanel.abelKit._admin.get('contracts').getSettingForSelectedContract(setting);
    }
});
