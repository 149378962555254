
var mp_polygons = [];
var mp_selected_polygon = null;
var mp_selected_polygon_changed = false;
var mp_markers = [];
var mp_map = null;
var mp_drawingmanager = null;
var mp_polygon_options = {
  fillColor: '#194C47',
  //fillColor: '#FFF27F',
  fillOpacity: 0.4,
  strokeWeight: 3,
  clickable: true,
  draggable: true,
  editable: false,
  zIndex: 1
};
var mp_infowindow = null;

var mp_marker_options = {
  icon: {
    path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
    fillColor: '#B2E5E0',
    //fillColor: '#FFF27F',
    fillOpacity: 0.9,
    scale: 0.8,
    //strokeColor: 'gold',
    strokeWeight: 3
  },
  clickable: true,
  draggable: true,
  zIndex: 2
};

function meetingpoints_load() {
  $("#content").html(Templates['settings_meetingpoints']({}));
  resetLinkListener();

  mp_map = new google.maps.Map(document.getElementById('meetingpoints_map'));
  mp_map.fitBounds(mapFittingBounds(adminPanel.abelKit._admin.get('contracts').getSettingForSelectedContract('mapBoundingBox')));

  mp_drawingmanager = new google.maps.drawing.DrawingManager({
    //drawingMode: google.maps.drawing.OverlayType.MARKER,
    drawingMode: null,
    drawingControl: true,
    drawingControlOptions: {
      position: google.maps.ControlPosition.TOP_CENTER,
      drawingModes: ['marker', 'polygon']
    },
    markerOptions: mp_marker_options,
    polygonOptions: mp_polygon_options
  });
  mp_drawingmanager.setMap(mp_map);

  meetingpoints_set_listeners(mp_drawingmanager);

  meetingpoints_load_polygons();
  return true;
}


function meetingpoints_set_listeners(drawingManager){
  google.maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon) {
    mp_polygons.push({id:null, polygon: polygon});
    meetingpoints_set_polygon_listeners(polygon);
    meetingpoints_select_polygon(polygon);
    mp_selected_polygon_changed = true;
  });

  google.maps.event.addListener(drawingManager, 'markercomplete', function(marker) {
    if (mp_selected_polygon == null) {
      //Do growl
      $.bootstrapGrowl('Add or select a polygon first', {'delay':2000, 'type':'danger'});

      //Reverse adding the marker
      marker.setMap(null);
    } else {
      mp_selected_polygon_changed = true;
      mp_markers.push({id:null, marker: marker, languages: []});
      meetingpoints_show_infowindow(marker, adminPanel.abelKit._admin.get('contracts').getSettingForSelectedContract('languages')[0].type);
      meetingpoints_set_marker_listeners(marker);
    }
  });


  $("#meetingpoints_polygon_drag_toggle").change(function() {
    if (!this.checked) {
      var draggable = false;
    } else {
      var draggable = true;
    }

    for(i=0;i<mp_polygons.length;i++){
      mp_polygons[i].polygon.setDraggable(draggable);
    }
  });


  $('#settings_meetingpoints_upload_form').submit(function(e) {
    if (!window.FormData) {
      $.bootstrapGrowl('Your browser is outdated. Use chrome.', {'delay':2000, 'type':'danger'});
      return false;
    } else {
      $.ajaxWrapper(
        'Meetingpoints/Upload/',
        'formData', //type
        new FormData($('#settings_meetingpoints_upload_form')[0]),
        true, //notification
        {
          success: function(data){
            $(window).trigger('hashchange');
            $.bootstrapGrowl('Done.', {'delay':2000, 'type':'success'});
          }
        } //ajax options
      );
      return false;
    }
  });
}

function meetingpoints_set_polygon_listeners(polygon) {
  meetingpoints_set_polygon_path_listeners(polygon);

  polygon.addListener('dragend', function(event) {
    //Delete a vertex
    mp_selected_polygon_changed = true;
  });

  polygon.addListener('rightclick', function(event) {
    var key = meetingpoints_get_key_for_polygon(polygon);

    if (event.vertex == undefined || polygon.getPath().getLength < 3) return;

    //remove vertex
    polygon.getPath().removeAt(event.vertex);

  });

  polygon.addListener('click', function(event) {
    meetingpoints_select_polygon(polygon);
  });
}

function meetingpoints_set_marker_listeners(marker) {
  marker.addListener('dragend', function(event) {
    mp_selected_polygon_changed = true;
  });
  marker.addListener('click', function(event) {
    meetingpoints_show_infowindow(marker, adminPanel.abelKit._admin.get('contracts').getSettingForSelectedContract('languages')[0].type);
  });
}

function meetingpoints_load_polygons() {
  $.ajaxWrapper(
    'Meetingpoints/', //resource
    'GET', //type
    {}, //data,
    false, //notification
    {
      success: function(data){
        //Create polygons
        for(i=0;i<data.Polygons.length;i++) {
          var polygon = new google.maps.Polygon(mp_polygon_options);
          polygon.setDraggable($("#meetingpoints_polygon_drag_toggle").prop('checked'));
          polygon.setPath(data.Polygons[i].path);
          polygon.setMap(mp_map);
          mp_polygons.push({id:data.Polygons[i].id, polygon: polygon});
          meetingpoints_set_polygon_listeners(polygon);
        }
      }
    } //ajax options
  );
}

function meetingpoints_load_markers(polygonId, key, showToUser) {
  $.ajaxWrapper(
    'Meetingpoints/'+polygonId, //resource
    'GET', //type
    {}, //data,
    false, //notification
    {
      success: function(data){
        //Update path
        mp_polygons[key].polygon.setPath(data.Polygon.path);
        meetingpoints_set_polygon_path_listeners(mp_polygons[key].polygon);

        //Create markers
        if (showToUser && key == mp_selected_polygon) {
          for(i=0;i<data.Meetingpoints.length;i++){
            var maropt = mp_marker_options;
            maropt.position = data.Meetingpoints[i].position;
            var marker = new google.maps.Marker(maropt);
            marker.setMap(mp_map);
            mp_markers.push({id:data.Meetingpoints[i].id, marker: marker, languages: data.Meetingpoints[i].languages});
            meetingpoints_set_marker_listeners(marker);
          }
        }
      }
    } //ajax options
  );
}

function meetingpoints_save_polygon_with_markers(clearPolygonSelectionAfter) {

  if (mp_selected_polygon != null) {
    var key = mp_selected_polygon; //Make a copy, as there is asynchronicity involved: in the mean time mp_selected_polygon changes in some situations
    var boundingPolygon = [];
    mp_polygons[key].polygon.getPaths().getAt(0).forEach(function(element, number){
      boundingPolygon.push({Y: element.lat(), X: element.lng()});
    });
    var meetingPoints = [];
    for(i=0;i<mp_markers.length;i++){
      meetingPoints.push({
        Location: {Y: mp_markers[i].marker.getPosition().lat(), X: mp_markers[i].marker.getPosition().lng()},
        Languages: mp_markers[i].languages
      })
    }
    var data = {
      BoundingPolygon: boundingPolygon,
      MeetingPoints: meetingPoints,
    };

    if (mp_polygons[key].id == null) {
      //Add
      var polygonId = '';
    } else {
      //Update
      var polygonId = mp_polygons[key].id;
    }

    $.ajaxWrapper(
      'Meetingpoints/'+polygonId, //resource
      'POST', //type
      data, //data,
      false, //notification
      {
        success: function(data){
          //Update Id
          mp_polygons[key].id = data.id;

          //Deselect
          if (clearPolygonSelectionAfter) {
            mp_selected_polygon_changed = false;
            meetingpoints_clear_polygon_selection();
          }
        }
      } //ajax options
    );

  } else {
    $.bootstrapGrowl('No polygon selected, so nothing saved.', {'delay':2000, 'type':'danger'});
  }
}


function meetingpoints_select_polygon(polygon) {
  var indexOfNewlySelectedPolygon = meetingpoints_get_key_for_polygon(polygon);
  if (indexOfNewlySelectedPolygon != mp_selected_polygon) {
    meetingpoints_clear_polygon_selection();
    mp_selected_polygon = meetingpoints_get_key_for_polygon(polygon); //Don't use indexOfNewlySelectedPolygon, as the functions above may have shifted it
    if (mp_polygons[mp_selected_polygon].id != null) meetingpoints_load_markers(mp_polygons[mp_selected_polygon].id, mp_selected_polygon, true);

    polygon.setOptions({fillColor: '#FFF27F'});
    polygon.setEditable(true);
  }
}

function meetingpoints_set_polygon_path_listeners(polygon) {
  polygon.getPaths().forEach(function(path, index){
    path.addListener('set_at', function(event) {
      //Drag an existing vertex
      mp_selected_polygon_changed = true;
    });

    path.addListener('insert_at', function(event) {
      //Create new vertex from between two edges
      mp_selected_polygon_changed = true;
    });

    path.addListener('remove_at', function(event) {
      //Delete a vertex
      mp_selected_polygon_changed = true;
    });
  });
}

function meetingpoints_get_key_for_polygon(polygon) {
  for(i=0;i<mp_polygons.length;i++){
    if (mp_polygons[i].polygon == polygon) {
      return i;
    }
  }
}

function meetingpoints_get_key_for_marker(marker) {
  for(i=0;i<mp_markers.length;i++){
    if (mp_markers[i].marker == marker) {
      return i;
    }
  }
}

function meetingpoints_get_language(languages, lang) {
  for(i=0;i<languages.length;i++){
    if (languages[i].language == lang) {
      return languages[i];
    }
  }
  return false;
}

function meetingpoints_show_infowindow(marker, lang) {
  if (mp_infowindow != null) mp_infowindow.close();

  var key = meetingpoints_get_key_for_marker(marker);
  var selected_language = meetingpoints_get_language(mp_markers[key].languages, lang);
  if (selected_language == false) {
    selected_language = {language: lang, name: '', description: ''};
  }

  mp_infowindow = new google.maps.InfoWindow({
      content: Templates['settings_meetingpoints_popover']({index: key, selected_language: selected_language}),
      zIndex: 3
  });
  mp_infowindow.open(marker.getMap(), marker);
  mp_infowindow.addListener('domready', function(event) {
    $('#meetingpoints_popover_language').change(function() {
      meetingpoints_popover_save(key, lang, false);
      meetingpoints_show_infowindow(marker, $('#meetingpoints_popover_language').val());
    });
    $('#meetingpoints_popover_form').submit(function(e) {
      return false;
    });
  });
}

function meetingpoints_clear_polygon_selection() {
  if (mp_selected_polygon != null && (mp_selected_polygon_changed || mp_polygons[mp_selected_polygon] == null)) {
    if (confirm("Do you want to save changes to the previous polygon?") == true) {
      meetingpoints_save_polygon_with_markers(false);
    } else {
      //Revert
      meetingpoints_revert_polygon_with_markers(false);
    }
  }
  for(i=0;i<mp_polygons.length;i++){
    mp_polygons[i].polygon.setOptions({fillColor: '#194C47'});
    mp_polygons[i].polygon.setEditable(false);
  }
  meetingpoints_clear_markers();
  mp_selected_polygon = null;
  mp_selected_polygon_changed = false;
}

function meetingpoints_clear_markers() {
  for(i=mp_markers.length-1;i>=0;i--){
    mp_markers[i].marker.setMap(null);
    mp_markers.pop();
  }
}

function meetingpoints_revert_polygon_with_markers(showToUser) {
  if (mp_selected_polygon == null) {
    $.bootstrapGrowl('Create/ edit a polygon first', {'delay':2000, 'type':'danger'});
    return;
  }

  if (!showToUser || confirm("Are you sure you want to revert?") == true) {
    meetingpoints_clear_markers();
    if (mp_polygons[mp_selected_polygon].id == null) {
      //Remove it
      mp_polygons[mp_selected_polygon].polygon.setMap(null);
      mp_polygons.splice(mp_selected_polygon, 1);
    } else {
      meetingpoints_load_markers(mp_polygons[mp_selected_polygon].id, mp_selected_polygon, showToUser);
    }
  } else {
    $.bootstrapGrowl('No changes made', {'delay':2000, 'type':'info'});
  }
}

function meetingpoints_remove_polygon_with_markers() {
  if (mp_selected_polygon == null) {
    $.bootstrapGrowl('Select a polygon first', {'delay':2000, 'type':'danger'});
    return;
  }

  if (confirm("Are you sure you want to remove it?") == true) {
    if (mp_polygons[mp_selected_polygon].id != null) {
      $.ajaxWrapper(
        'Meetingpoints/'+mp_polygons[mp_selected_polygon].id+'/Remove', //resource
        'POST', //type
        {}, //data,
        false, //notification
        {
          success: function(data){
          }
        } //ajax options
      );
    }
    if (mp_selected_polygon != null) {
      mp_polygons[mp_selected_polygon].polygon.setMap(null);
      mp_polygons.splice(mp_selected_polygon, 1);
    }
    mp_selected_polygon = null;
    mp_selected_polygon_changed = false;
    meetingpoints_clear_polygon_selection();
  } else {
    $.bootstrapGrowl('No changes made', {'delay':2000, 'type':'info'});
  }
}

function meetingpoints_popover_remove(index) {
  if (confirm("Are you sure you want to remove this meeting point?") == true) {
    mp_markers[index].marker.setMap(null);
    mp_markers.splice(index, 1);
    mp_selected_polygon_changed = true;
  } else {
    $.bootstrapGrowl('No changes made', {'delay':2000, 'type':'info'});
  }
}

function meetingpoints_popover_save(index, language, close) {
  selected_language = meetingpoints_get_language(mp_markers[index].languages, language);
  if(selected_language == false) {
    mp_markers[index].languages.push({language: language, name: $('#meetingpoints_popover_name').val(), description: $('#meetingpoints_popover_description').val()});
  } else {
    selected_language.name = $('#meetingpoints_popover_name').val();
    selected_language.description = $('#meetingpoints_popover_description').val();
  }
  if (close && mp_infowindow != null) mp_infowindow.close();
}

function meetingpoints_download_csv() {
    adminPanel.abelKit._auth.getValidToken(function (token) {
        var params = {
          Auth: token,
          ContractId: adminPanel.abelKit._admin.get('contracts').getActive().id
        };

        window.open(adminPanel.abelKit.getServer()+'AbelAdminService/Meetingpoints/Download/?' + $.param(params), 'Download meetingpoints');
    }, function (error) {
        $.bootstrapGrowl('Auth error: ' + error.message, {'delay':2000, 'type':'danger'});
        console.log('Auth error: ' + error.message);
    });

}
