function lookup_trip_load(data){
  data.selectedContract = adminPanel.abelKit._admin.get('contracts').getActive();

  $("#content").html(Templates['lookup_trip'](data));
  resetLinkListener();
  $('[data-toggle="tooltip"]').tooltip();

  $("#lookup_trip_form").submit(function(e) {
    //lookup_trip_search();
    document.location.hash = 'lookup_trip/search/' + encodeURIComponent($('#trip_search').val());
    return false;
  });

  return true;
}

function lookup_trip_load_new(customer_id) {
    $("#content").html(Templates['lookup_trip_add']({
      customer_id: customer_id,
      pickup_timewindow_from: moment().tz(adminPanel.abelKit._admin.get('timezone')).format('YYYY-MM-DDTHH:mm'),
      pickup_timewindow_to: moment().add(20, 'minutes').tz(adminPanel.abelKit._admin.get('timezone')).format('YYYY-MM-DDTHH:mm')
    }));
    resetLinkListener();

    //Attach autocomplete
    adminPanel.trip();
    adminPanel.view.new();

    $("#lookup_trip_form_new").submit(function(e) {
      var flexibility = {
        TimeWindows: {},
        MaximumDetourIntervalMinutes:	$('#trip_maximum_detour_interval').val(),
        NotificationIntervalMinutes:	$('#trip_notification_interval').val()
      }

      var pickupTimewindowFrom = moment.tz($('#trip_pickup_timewindow_from').val(), adminPanel.abelKit._admin.get('timezone'));
      var pickupTimewindowTo = moment.tz($('#trip_pickup_timewindow_to').val(), adminPanel.abelKit._admin.get('timezone'));
      var deliveryTimewindowFrom = moment.tz($('#trip_delivery_timewindow_from').val(), adminPanel.abelKit._admin.get('timezone'));
      var deliveryTimewindowTo = moment.tz($('#trip_delivery_timewindow_to').val(), adminPanel.abelKit._admin.get('timezone'));

      if (pickupTimewindowFrom.isValid() && pickupTimewindowTo.isValid()) {
        flexibility.TimeWindows.PickupTimeWindow = {
          EarliestTime: pickupTimewindowFrom.toISOString(),
          LatestTime: pickupTimewindowTo.toISOString()
        };
      }

      if (deliveryTimewindowFrom.isValid() && deliveryTimewindowTo.isValid()) {
        flexibility.TimeWindows.DeliveryTimeWindow = {
          EarliestTime: deliveryTimewindowFrom.toISOString(),
          LatestTime: deliveryTimewindowTo.toISOString()
        };
      }

      $.ajaxWrapper(
        'Trip/Book', //resource
        'POST', //type
        {
          Trip: {
            CustomerId: $('#trip_customer_id').val().trim(),
            LocationFrom: {
              GeoType: 'wgs84',
              LocationType: 'gpscoord',
              Coord: {
                Y: $('#trip_from_lat').val(),
                X: $('#trip_from_lng').val()
              },
              Street: $('#trip_from_route').val(),
              HouseNumber: $('#trip_from_street_number').val(),
              POIName: $('#trip_from_poi_name').val(),
              Place: $('#trip_from_locality').val(),
              Zip: $('#trip_from_postal_code').val(),
              CountryCode: $('#trip_from_country').val()
            },
            LocationTo: {
              GeoType: 'wgs84',
              LocationType: 'gpscoord',
              Coord: {
                Y: $('#trip_to_lat').val(),
                X: $('#trip_to_lng').val()
              },
              Street: $('#trip_to_route').val(),
              HouseNumber: $('#trip_to_street_number').val(),
              POIName: $('#trip_to_poi_name').val(),
              Place: $('#trip_to_locality').val(),
              Zip: $('#trip_to_postal_code').val(),
              CountryCode: $('#trip_to_country').val()
            },
            Attributes: {
              Passengers: $('#trip_passengers').val(),
              Wheelchairs: $('#trip_wheelchairs').val()
            }
          },
          Flexibility: flexibility,
          PaymentMethodId: $('#trip_payment_method_id').val()
        }, //data,
        false, //notification
        {
          success: function(data){
            document.location.hash = 'lookup_trip/' + data.TripId;
            $.bootstrapGrowl('Added.', {'delay':2000, 'type':'success'});
          }
        } //ajax options
      );
      return false;
    });
}



function lookup_trip_search(query){
  if (typeof query !== 'undefined') {
    $('#trip_search').val(query);
  }
  $.ajaxWrapper(
    'Trip/Search/'+encodeURI($('#trip_search').val()), //resource
    'GET', //type
    {}, //data,
    false, //notification
    {
      success: function(data){
        //Unhide result table
        $("#searchtable").html(Templates['lookup_trip_searchtable']({trips: lookup_trip_parse_trips(data.return)}));
        resetLinkListener();
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        $('#searchtable').innerHTML = '';
      }
    } //ajax options
  );
}

function lookup_trip_lookup(tripid){
  $.ajaxWrapper(
      'Trip/'+tripid, //resource
      'GET', //type
      {}, //data,
      true, //notification
      {
        success: function(data){
          //Unhide result table
          lookup_trip_load({trip:lookup_trip_parse_trip(data.return.trip), middleware_server: adminPanel.abelKit.getServer()});
        },
        error: function(xhr, errorType, exception) {
          //Hide result table
          lookup_trip_load({});
        }
      } //ajax options
    );
}

function lookup_trip_parse_trips(trips) {
  for (i = 0; i < trips.length; i++) {
    lookup_trip_parse_trip(trips[i]);
  }
  return trips;
}

function lookup_trip_parse_trip(trip) {
  now = moment();
  dbt = moment(trip.DBOOKINGTIME); //booking time
  dept = moment(trip.DEPT); //earliest pickup time
  dlpt = moment(trip.DLPT); //latest pickup time
  drpt = trip.DREALPT != false ? moment(trip.DREALPT) : moment.invalid(); //realized pickup time
  dedt = moment(trip.DEDT); //earliest delivery time
  dldt = moment(trip.DLDT); //latest delivery time
  drdt =  trip.DREALDT != false ? moment(trip.DREALDT) : moment.invalid(); //realized delivery time
  if ((drpt.isValid() && drpt.diff(dlpt) < 0) || (!drpt.isValid() && dlpt.diff(now) > 0)) {
    //If realized before latest time or if not realized yet and latest time is later than now
    trip.p_on_time = true;
  } else {
    trip.p_on_time = false;
  }

  if (drpt.isValid()) trip.pickup_diff = lookup_trip_number_incl_sign(drpt.diff(dlpt, 'minutes'));
  if (drdt.isValid()) trip.delivery_diff = lookup_trip_number_incl_sign(drdt.diff(dldt, 'minutes'));

  if ((drdt.isValid() && drdt.diff(dldt) < 0) || (!drdt.isValid() && dldt.diff(now) > 0))  {
    //If realized before latest time or if not realized yet and latest time is later than now
    trip.d_on_time = true;
  } else {
    trip.d_on_time = false;
  }

  trip.action_confirm = false;
  trip.action_cki = false;
  trip.action_cko = false;
  trip.action_cancel = false;
  trip.action_driver_cancel = false;
  trip.p_realized = false;
  trip.d_realized = false;

  if (trip.tripstatus == 'creditCardFailed') {
    //None
  } else if (trip.tripstatus == 'tripDeclined') {
    //None
  } else if (trip.tripstatus == 'tripReceived') {
    trip.action_cancel = true;
  } else if (trip.tripstatus == 'tripAccepted') {
    trip.action_cancel = true;
  } else if (trip.tripstatus == 'paymentAuthorized') {
    trip.action_cancel = true;
  } else if (trip.tripstatus == 'awaitingDriverConsent') {
    trip.action_confirm = true;
    trip.action_cancel = true;
  } else if (trip.tripstatus == 'driverAccepted') {
    trip.action_cki = true;
    trip.action_cancel = true;
    trip.action_driver_cancel = true;
  } else if (trip.tripstatus == 'customerPickedUp') {
    trip.action_cko = true;
    trip.action_driver_cancel = true;
    trip.p_realized = true;
  } else if (trip.tripstatus == 'customerArrived') {
    trip.p_realized = true;
    trip.d_realized = true;
  } else if (trip.tripstatus == 'customerCancelled') {
      //None
  } else if (trip.tripstatus == 'driverCancelledBecauseOfClient') {
      //None
  } else if (trip.tripstatus == 'driverCancelledBecauseOfDriver') {
    trip.p_realized = true; //could be
  } else if ('driverCancelledBecauseOfDriverWithReplan') {
    trip.p_realized = true; //could be
  } else if (trip.tripstatus == 'refunded') {
    trip.p_realized = true;
    trip.d_realized = true;
  }

  if ((trip.discount.nonzero || trip.charged.nonzero) && (typeof trip.pending == 'undefined' || trip.pending.refund == false) && (trip.tripstatus == 'customerArrived' || trip.tripstatus == 'customerCancelled' || trip.tripstatus == 'driverCancelledBecauseOfClient')) {
      trip.action_withdraw_invoice = true;
  } else {
      trip.action_withdraw_invoice = false;
  }

  trip.olat_dec = trip.OLAT/10000000;
  trip.olon_dec = trip.OLON/10000000;
  trip.dlat_dec = trip.DLAT/10000000;
  trip.dlon_dec = trip.DLON/10000000;
  return trip;
}

function lookup_trip_change_state(tripid,driverid,customerid,action) {
  if (confirm("Are you sure?") == true) {
    $.ajaxWrapper(
        'Trip/'+tripid+'/'+action, //resource
        'POST', //type
        {DriverId: driverid, CustomerId:customerid}, //data,
        true, //notification
        {
          success: function(data){
            $.bootstrapGrowl('Done.', {'delay':2000, 'type':'success'});
            lookup_trip_lookup(tripid);
          }
        } //ajax options
      );
  } else {
    $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
  }
}

function lookup_trip_number_incl_sign(theNumber)
{
    if(theNumber > 0){
        return "+" + theNumber;
    }else{
        return theNumber.toString();
    }
}
