function lookup_company_load(data){
  $("#content").html(Templates['lookup_company'](data));
  resetLinkListener();

  //Cath enter in typeahead
  $("#lookup_company_form").submit(function(e) {
    //lookup_company_search();
    document.location.hash = 'lookup_company/search/' + encodeURIComponent($('#company_search').val());
    return false;
  });

  //Do typeahead
  /*$("#company_search").bind('change paste keyup',function() {
      lookup_company_search();
  });*/

  //Update company
  $("#company_form").submit(function(e) {
    if (typeof $('#company_id').val() === 'undefined') {
      //add
      $.ajaxWrapper(
        'Company/', //resource
        'POST', //type
        {Company: {
          StatutoryName: $('#company_name').val().trim(),
          StatutoryAddress: {
            LocationType: 'address',
            Street: $('#company_street').val().trim(),
            HouseNumber: $('#company_housenumber').val().trim(),
            Zip: $('#company_zip').val().trim(),
            Place: $('#company_place').val().trim(),
            CountryCode: $('#company_country').val().trim()
          },
          Email: $('#company_email').val().trim(),
          VatNumber: $('#company_vat').val().trim(),
          KvKNumber: $('#company_kvk').val().trim(),
          IBAN: $('#company_iban').val().trim()
        }}, //data,
        false, //notification
        {
          success: function(data){
            document.location.hash = 'lookup_company/' + data.result.CompanyExtended.CompanyId;
            $.bootstrapGrowl('Added.', {'delay':2000, 'type':'success'});
          }
        } //ajax options
      );
    } else {
      //update
      $.ajaxWrapper(
        'Company/'+$('#company_id').val().trim(), //resource
        'POST', //type
        {Company: {
          StatutoryName: $('#company_name').val().trim(),
          StatutoryAddress: {
            LocationType: 'address',
            Street: $('#company_street').val().trim(),
            HouseNumber: $('#company_housenumber').val().trim(),
            Zip: $('#company_zip').val().trim(),
            Place: $('#company_place').val().trim(),
            CountryCode: $('#company_country').val().trim()
          },
          Email: $('#company_email').val().trim(),
          VatNumber: $('#company_vat').val().trim(),
          KvKNumber: $('#company_kvk').val().trim(),
          IBAN: $('#company_iban').val()
        }}, //data,
        false, //notification
        {
          success: function(data){
            $.bootstrapGrowl('Saved.', {'delay':2000, 'type':'success'});
          }
        } //ajax options
      );
    }
    return false;
  });
  return true;

}

function lookup_company_remove(id) {
  if (confirm("Are you sure?") == true) {
    $.ajaxWrapper(
      'Company/'+$('#company_id').val() + '/Remove', //resource
      'POST', //type
      {}, //data,
      false, //notification
      {
        success: function(data){
          document.location.hash = 'lookup_company';
          $.bootstrapGrowl('Removed.', {'delay':2000, 'type':'success'});
        }
      } //ajax options
    );
  } else {
    $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
  }
}

function lookup_company_new() {
  lookup_company_load({company:{new:true}});
}

function lookup_company_search(query){
  if (typeof query !== 'undefined') {
    $('#company_search').val(query);
  }
  $.ajaxWrapper(
    'Company/Search/'+encodeURI($('#company_search').val()), //resource
    'GET', //type
    {}, //data,
    false, //notification
    {
      success: function(data){
        //Unhide result table
        $("#searchtable").html(Templates['lookup_company_searchtable']({companies: data.return}));
        resetLinkListener();
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        $('#searchtable').innerHTML = '';
      }
    } //ajax options
  );
}

function lookup_company_lookup(company_id){
  $.ajaxWrapper(
    'Company/'+company_id, //resource
    'GET', //type
    {}, //data,
    true, //notification
    {
      success: function(data){
        //Unhide result table
        lookup_company_load({company:data.return.company, drivers:data.return.drivers});
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        lookup_company_load({});
      }
    } //ajax options
  );
}
