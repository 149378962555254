var map_timer;
var map_time = '';
var refreshInterval = 15000; //ms
var display_format = 'YYYY-MM-DD HH:mm:ss';

function map_load() {
  //Set current time
  $('#map_control_time').val(moment().tz(adminPanel.abelKit._admin.get('timezone')).format('YYYY-MM-DDTHH:mm'));

  //Catch form submit
  $("#map_control_form").submit(function(e) {
    return false;
  });

  //Catch play/pause change
  $('#map_control_play').on('click', function () {
    map_action('play');
  });

  $('#map_control_pause').on('click', function () {
    map_action('stop');
  });


  $("#map_control_time").change(function(e) {
    map_time = moment.tz($('#map_control_time').val(), adminPanel.abelKit._admin.get('timezone'));
  });

  //Catch clik on datetime
  $('#map_control_time').on('click', function () {
    if ($('#map_control_time').attr('readonly')) {

      //Change map_time from '' to current time
      map_time = moment.tz($('#map_control_time').val(), adminPanel.abelKit._admin.get('timezone'));

      //stop the time
      map_action('stop');
    }
  });

    // Create a map object and specify the DOM element for display.
    map = loadMap('map');
    centerMap();
    map_action('play');
}

/**
 * Center the map on the current contract's
 * preferred bounding box.
 */
function centerMap() {
    map.fitBounds(mapFittingBounds(
        adminPanel.abelKit._admin.get('contracts').getSettingForSelectedContract('mapBoundingBox')
    ));
}

function map_action(action) {
  if (action == 'play') {
    $('#map_control_time').attr('readonly',true);

    clearInterval(map_timer); //to be sure
    get_update(0); //run one already

    map_timer = setInterval(function(){
      get_update(refreshInterval);
    }, refreshInterval);
    $('#map_control_pause').removeClass('active');
    $('#map_control_play').addClass('active');
  } else {
    //stop
    clearInterval(map_timer);
    $('#map_control_time').attr('readonly',false);
    $('#map_control_pause').addClass('active');
    $('#map_control_play').removeClass('active');
  }
}

function map_reset_time() {
  map_time = '';
  map_action('play');
}

function map_update_time(increaseInMs) {
  if (map_time != '') {
    //We are in playback mode
    var speed = parseInt($("#map_control_speed").val());
    map_time = moment.tz($('#map_control_time').val(), adminPanel.abelKit._admin.get('timezone'));
    if (map_time.isValid()) {
      map_time = map_time.add(increaseInMs*speed, 'ms');
      $('#map_control_time').val(map_time.format(display_format));  //update time
    } else {
      map_time = '';
      $('#map_control_time').val(moment().tz(adminPanel.abelKit._admin.get('timezone')).format(display_format));  //Only show updated time
    }
  } else {
    $('#map_control_time').val(moment().tz(adminPanel.abelKit._admin.get('timezone')).format(display_format));  //Only show updated time
  }
}

function set_time_and_get_update(increaseInMs) {
  map_action('stop');
  map_time = moment.tz($('#map_control_time').val(), adminPanel.abelKit._admin.get('timezone'));
  if (!(map_time.isValid())) map_time = moment.tz(adminPanel.abelKit._admin.get('timezone'));
  get_update(increaseInMs);
}

function get_update(increaseInMs) {
  //Determine time
  map_update_time(increaseInMs);

  if (map_time != '') {
    var backend_time = map_time.toISOString();
  } else {
    var backend_time = moment().toISOString();
  }

  $.ajaxWrapper(
    'Map/State/'+backend_time, //resource, time to get statusupdate from
    'GET', //type
    {}, //data,
    true, //notification
    {
      success: function(data){
        untouchMarkers();
        $.each(data.return.drivers, function(key, value) {
          var driver_name = value.driver_name + ' ' + moment.tz(value.time, adminPanel.abelKit._admin.get('timezone')).format('HH:mm');
          addMarker(map,'d'+key,value.position,driver_name,{type: 'driver', id: value.driver_id});
        });

        audio_to_play ={};
        $.each(data.return.customers, function(key, value) {
          if (value.checked_in == false) {
            retval = addMarker(map,'c'+key,value.position, value.customer_name, {type: 'client', state:'origin', driver: value.driver, on_time:  value.on_time, passengers: Math.min(value.passengers,3), time_waiting: value.time_waiting});
          } else {
            retval = addMarker(map,'c'+key,value.position, value.customer_name, {type: 'client', state:'transit', driver: value.driver, on_time:  value.on_time, passengers: Math.min(value.passengers,3), time_waiting: value.time_waiting});
          }

          //Add all properties of retval to audio_to_play
          for (var attrname in retval) { audio_to_play[attrname] = retval[attrname];}
        });

        removeUntouchedMarkers();

        //play sounds if required

        if ('waiting_too_long' in audio_to_play) {
          document.getElementById('audio_alarm').play();
        } else if ('changed_to_late' in audio_to_play) {
          document.getElementById('audio_flute').play();
        } else if ('new_client' in audio_to_play) {
          document.getElementById('audio_ding').play();
        }
        //new_driver

        //addTripPredictionBlocks(map, data.return.forecast);
      }
    } //ajax options
  );
}

function map_get_time(type) {
  //type: FastForward or FastBackward
  //get time of next or previous event (booking, checkin, checkout or cancel)
  map_update_time(0);
  if (map_time != '') {
    var backend_time = map_time;
  } else {
    var backend_time = moment();
  }

  if (type == 'FastForward') {
    backend_time.add(1,'s'); //otherwise, we get the same result back again, due to rounding
  }

  var backend_time_formatted = backend_time.toISOString();

  $.ajaxWrapper(
    'Map/'+type+'/'+backend_time_formatted, //resource, time to get statusupdate from
    'GET', //type
    {}, //data,
    true, //notification
    {
      success: function(data){
        map_action('stop');
        if (!data.return.event_time) {
            $('#map_control_time').val(moment().tz(adminPanel.abelKit._admin.get('timezone')).format('YYYY-MM-DDTHH:mm'));
        } else {
            $('#map_control_time').val(data.return.event_time);
        }
        map_time = moment.tz($('#map_control_time').val(), adminPanel.abelKit._admin.get('timezone'));
        map_action('play');
      }
    } //ajax options
  );
}

function addTripPredictionBlocks(map, tripPredictionBlocks) {
  for (var i = 0; i < tripPredictionPolygons.length; i++) {
      //If you want, check here for some constraints.
      tripPredictionPolygons[i].setMap(null);
  };
  tripPredictionPolygons = [];

  var maxPrediction = 0;
  for (var i = 0; i < tripPredictionBlocks.length; i++) {
    if (maxPrediction < tripPredictionBlocks[i].prediction) maxPrediction = tripPredictionBlocks[i].prediction;
    //if (tripPredictionBlocks[i].prediction > 0) console.log(tripPredictionBlocks[i].prediction);
  }

  for (var i = 0; i < tripPredictionBlocks.length; i++) {
    var coords = [
      {lat: tripPredictionBlocks[i].lat_max, lng: tripPredictionBlocks[i].lon_max},
      {lat: tripPredictionBlocks[i].lat_max, lng: tripPredictionBlocks[i].lon_min},
      {lat: tripPredictionBlocks[i].lat_min, lng: tripPredictionBlocks[i].lon_min},
      {lat: tripPredictionBlocks[i].lat_min, lng: tripPredictionBlocks[i].lon_max}
    ]
    var fillColor = ratioToHeatmapColor(tripPredictionBlocks[i].prediction/maxPrediction);


    var block = new L.Polygon(coords, {
         strokeWeight: 0,
         fillColor: fillColor,
         fillOpacity: 0.3
       });
     block.setMap(map);
     tripPredictionPolygons.push(block);
  }
}

function ratioToHeatmapColor(x) {
  x = Math.max(Math.min(x,1),0);

  var colors = [
    '2433ff', // dark blue
    '91d200', // medium green
    'ecee0a', // yellow
    'ee2c0a'  // red
  ]
  var scale = [0, 0.3, 0.6, 1];

  for (var i = 0; i < scale.length; i++) {
    if ((x >= scale[i-1] && x < scale[i]) || (i == scale.length - 1 && x == scale[i])) {
      var color1 = colors[i-1];
      var color2 = colors[i];
      var factor = (x - scale[i-1]) / (scale[i] - scale[i-1]);
    }
  }

  var hex = function(x) {
      x = x.toString(16);
      return (x.length == 1) ? '0' + x : x;
  };

  var r = Math.ceil(parseInt(color1.substring(0,2), 16) * (1-factor) + parseInt(color2.substring(0,2), 16) * factor);
  var g = Math.ceil(parseInt(color1.substring(2,4), 16) * (1-factor) + parseInt(color2.substring(2,4), 16) * factor);
  var b = Math.ceil(parseInt(color1.substring(4,6), 16) * (1-factor) + parseInt(color2.substring(4,6), 16) * factor);

  return '#' + hex(r) + hex(g) + hex(b);
}

var tripPredictionPolygons = [];

// can be used for testing markers
function getTestDriverMarker(){
    return [
        {
            driver_id: 1,
            driver_name: "test",
            time: new Date().getTime(),
            position: {lat: 52.379189, lng: 4.899431},
        }
    ];
}

function getTestCustomerMarkers(){
    return [
        {
            checked_in: true,
            customer_name: "test_customer1",
            on_time: false,
            position: {lat: 52.379189, lng: 4.899431},
            driver: 1,
            passengers: 3,
            time_waiting: 5,
        },
        {
            checked_in: true,
            customer_name: "test_customer2",
            on_time: true,
            position: {lat: 52.379189, lng: 4.899431},
            driver: 1,
            passengers: 2,
            time_waiting: 5,
        },
        {
            checked_in: true,
            customer_name: "test_customer3",
            on_time: false,
            position: {lat: 52.379189, lng: 4.899431},
            driver: 1,
            passengers: 2,
            time_waiting: 5,
        }
    ];
}
