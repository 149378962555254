function lookup_discount_load(data){
  $("#content").html(Templates['lookup_discount'](data));
  resetLinkListener();

  //Cath enter in typeahead
  $("#lookup_discount_form").submit(function(e) {
    //lookup_discount_search();
    document.location.hash = 'lookup_discount/search/' + encodeURIComponent($('#discount_search').val());
    return false;
  });

  //Do typeahead
  /*$("#discount_search").bind('change paste keyup',function() {
    lookup_discount_search();
  });*/
  return true;
}

function lookup_discount_remove(customer_id,discount_id,discount_type) {
  if (confirm("Are you sure?") == true) {
    $.ajaxWrapper(
      'Discount/Remove', //resource
      'POST', //type
      {CustomerId: customer_id, DiscountId: discount_id, DiscountType: discount_type}, //data,
      false, //notification
      {
        success: function(data){
          $(window).trigger('hashchange');
          $.bootstrapGrowl('Removed.', {'delay':2000, 'type':'success'});
        }
      } //ajax options
    );
  } else {
    $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
  }
}

function lookup_discount_search(query){
  if (typeof query !== 'undefined') {
    $('#discount_search').val(query);
  }
  $.ajaxWrapper(
    'Discount/Search/'+encodeURI($('#discount_search').val()), //resource
    'GET', //type
    {}, //data,
    false, //notification
    {
      success: function(data){
        //Unhide result table
        $("#searchtable").html(Templates['lookup_discount_searchtable']({discounts: data.return}));
        resetLinkListener();
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        $('#searchtable').innerHTML = '';
      }
    } //ajax options
  );
}

function lookup_discount_lookup(discount_id){
  $.ajaxWrapper(
    'Discount/'+discount_id, //resource
    'GET', //type
    {}, //data,
    true, //notification
    {
      success: function(data){
        //Unhide result table
        lookup_discount_load({discount:data.return.discount});
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        lookup_discount_load({});
      }
    } //ajax options
  );
}


function lookup_discount_add_all() {
  //Parse form content

  var lines = $('#discount_promocodes').val().split('\n');
  discounts = [];
  $.each(lines, function(){
    var line = this.split(',',2);
    line[0] = line[0].toLowerCase();
    line[1] = parseInt(line[1]);
    discounts.push({Promocode: line[0], CustomerId: line[1]});
  });

  $.ajaxWrapper(
    'Discount/', //resource
    'POST', //type
    {Discounts: discounts}, //data,
    true, //notification
    {
      success: function(data){
        $(window).trigger('hashchange');
      }
    } //ajax options
  );

  $('#addDiscountsModal').modal('hide');
}
