var graph_sql = {};

function reporting_graphs_load(segment){
  if (segment == 'clients' || segment == 'ops_graph') {
    $("#filter_granularity").val('days');
    if (segment == 'ops_graph') {
      filter_change_granularity(true, true);
    } else {
      filter_change_granularity(true, false);
    }
    $("#content").html(Templates['reporting_'+segment]({user: adminPanel.abelKit._admin.get(), contracts: adminPanel.abelKit._admin.get('contracts').data, selectedContract: adminPanel.abelKit._admin.get('contracts').getActive()}));
    resetLinkListener();
    $('[data-toggle="tooltip"]').tooltip();
  } else if (segment == 'ops_table') {
    $("#content").html('Loading...');
    filter_change_granularity(false,false); //Render right timespan selectors and hide granularity input box
  } else if (segment == 'finance') {
    filter_change_granularity(false,false,'Download'); //Render right timespan selectors and hide granularity input box
    $("#content").html('Note that download will trigger three files. Make sure that your popup-blocker allows this');
  }

  $('#filter_div').removeClass('hidden');
  reporting_get(segment, false);

  $("#filter_granularity").change(function(e) {
    if (segment == 'ops_graph') {
      filter_change_granularity(true, true);
    } else {
      filter_change_granularity(true, false);
    }
    reporting_get(segment, false);
  });

  $("#reporting_filter").submit(function(e) {
    reporting_get(segment, true);
    return false;
  });
}

function reporting_get(segment, button_pressed){
    var filter = filter_get();
    if (segment == 'clients') {
        /*if (driver != false) {
        data.driverid = driver;
    }*/
    $.ajaxWrapper(
        'Customer/Performance', //resource
        'GET', //type
        {grouping: filter.grouping, start: filter.first_period_start.toISOString(), end: filter.last_period_start.toISOString()}, //data,
        false, //notification
        {
            success: function(data){
                /*
                Expect two output parameters:
                SQL: the query
                data: a table of graph data, with the first column being the x-axis
                */
                var names = ['clients', 'clients_mutations', 'completed_trips'];
                for (var i = 0; i < names.length; i++) {
                    graph_clear('svg#' + names[i]);
                    reporting_graphs[names[i]].load(data.return, filter.grouping, filter.first_period_start, filter.last_period_start);
                    //graph_sql[names[i]] = data.return[names[i]+'_sql'];
                    graph_plot_graph(reporting_graphs[names[i]].series, reporting_graphs[names[i]].xAxisFormatter(), reporting_graphs[names[i]].yAxisFormatter, reporting_graphs[names[i]].seriesNames, reporting_graphs[names[i]].graphType(), names[i]);
                }
            }
        } //ajax options
    );
} else if (segment == 'ops_graph') {
    if (filter.driver_id == false) {
        resource = 'Driver/Performance/';
    } else {
        resource = 'Driver/Performance/' + filter.driver_id;
    }
    $.ajaxWrapper(
        resource, //resource
        'GET', //type
        {grouping: filter.grouping, start: filter.first_period_start.toISOString(), end: filter.last_period_start.toISOString()}, //data,
        false, //notification
        {
            success: function(data){
                /*
                Expect two output parameters:
                SQL: the query
                data: a table of graph data, with the first column being the x-axis
                */
                var names = [
                    'drivers_active',
                    'trips_and_shiftminutes',
                    'tripfare_and_shiftminutes',
                    'utilization',
                    'driver_rejects',
                    'tripstatus_split',
                    'acceptance_time',
                    'punctuality_pickup',
                    'punctuality_delivery',
                    'trip_rating'
                ];
                for (var i = 0; i < names.length; i++) {
                    graph_clear('svg#' + names[i]);
                    reporting_graphs[names[i]].load(data.return, filter.grouping, filter.first_period_start, filter.last_period_start);
                    //graph_sql[names[i]] = data.return[names[i]+'_sql'];
                    graph_plot_graph(reporting_graphs[names[i]].series, reporting_graphs[names[i]].xAxisFormatter(), reporting_graphs[names[i]].yAxisFormatter, reporting_graphs[names[i]].seriesNames, reporting_graphs[names[i]].graphType(), names[i]);
                }
            }
        } //ajax options
    );
} else if (segment == 'ops_table') {
    $.ajaxWrapper(
        'Driver/PerformancePerDriver', //resource
        'GET', //type
        {start: filter.first_period_start.toISOString(), end: filter.last_period_start.add(1, 'days').toISOString()}, //data,
        false, //notification
        {
            success: function(data){
                for (i = 0; i < data.return.data.length; i++) {
                    relative_utilization = (data.return.data[i].successful_trip_passenger_load/data.return.data[i].hours_active)/(data.return.data[i].all_successful_trip_passenger_load/data.return.data[i].all_hours_active)*100;
                    data.return.data[i].hours = data.return.data[i].hours_active.toFixed(1);
                    data.return.data[i].utilization = (data.return.data[i].successful_trip_passenger_load / data.return.data[i].hours_active).toFixed(1);
                    data.return.data[i].accept_reject_bool = ((data.return.data[i].accept_count + data.return.data[i].reject_count) > 0);
                    data.return.data[i].rejected_raw = (data.return.data[i].reject_count/(data.return.data[i].accept_count + data.return.data[i].reject_count)*100).toFixed(0);
                    data.return.data[i].rejected = data.return.data[i].rejected_raw + '%';
                    data.return.data[i].trips_bool = ((data.return.data[i].successful_trip_count + data.return.data[i].trips_canceled_by_driver_count) > 0);
                    data.return.data[i].canceled_raw = (data.return.data[i].trips_canceled_by_driver_because_of_driver_count/(data.return.data[i].successful_trip_count + data.return.data[i].trips_canceled_by_driver_count)*100).toFixed(0);
                    data.return.data[i].canceled = data.return.data[i].canceled_raw + '%';
                    data.return.data[i].noshow_raw = (data.return.data[i].trips_canceled_by_driver_because_of_customer_count/(data.return.data[i].successful_trip_count + data.return.data[i].trips_canceled_by_driver_count)*100).toFixed(0);
                    data.return.data[i].noshow = data.return.data[i].noshow_raw + '%';
                    data.return.data[i].response_time = data.return.data[i].accept_react_avg.toFixed(0);
                    data.return.data[i].punctuality_raw = ((data.return.data[i].successful_trip_on_time_count + data.return.data[i].successful_trip_delivery_on_time_count) / (data.return.data[i].successful_trip_on_time_count + data.return.data[i].successful_trip_pickup_on_time_count + data.return.data[i].successful_trip_delivery_on_time_count + data.return.data[i].successful_trip_nothing_on_time_count) * 100).toFixed(0);
                    data.return.data[i].punctuality = data.return.data[i].punctuality_raw + '%';
                    data.return.data[i].relative_utilization_raw =  relative_utilization.toFixed(0);
                    data.return.data[i].relative_utilization =  relative_utilization.toFixed(0) + '%';
                    if (data.return.data[i].successful_trip_rated_trip_count == 0) {
                        data.return.data[i].rating = NaN;
                    } else {
                        data.return.data[i].rating = data.return.data[i].successful_trip_rated_trip_avg_rating.toFixed(2);
                    }
                    data.return.data[i].rating_share = (data.return.data[i].successful_trip_rated_trip_count/ data.return.data[i].successful_trip_count * 100).toFixed(0) + '%';
                }
                //Unhide result table
                $("#content").html(Templates['reporting_ops_table']({drivers: data.return.data}));
                var csv_link = create_csv_link('#reporting_ops_table_table');
                csv_link.innerHTML = "Download as CSV";
                csv_link.setAttribute("class", 'btn btn-default');
                csv_link.setAttribute("role", 'button');
                $('#query_csv_export').html(csv_link);
                //$.bootstrapSortable();
                $.bootstrapSortable({ sign: "arrow reversed" });
                resetLinkListener();
            }
        } //ajax options
    );
} else if (segment == 'finance') {
    if (button_pressed) {
        adminPanel.abelKit._auth.getValidToken(function (token) {
            var params = {
                start: filter.first_period_start.toISOString(),
                end: filter.last_period_start.add(1, 'days').toISOString(),
                Auth: token,
                ContractId: adminPanel.abelKit._admin.get('contracts').getActive().id
            };

            window.open(adminPanel.abelKit.getServer()+'AbelAdminService/Finance/Backend?' + $.param(params), 'Download trips');
            window.open(adminPanel.abelKit.getServer()+'AbelAdminService/Finance/BackendRefunds?' + $.param(params), 'Download trip refunds');
            window.open(adminPanel.abelKit.getServer()+'AbelAdminService/Finance/Payments?' + $.param(params), 'Download payments');
            window.open(adminPanel.abelKit.getServer()+'AbelAdminService/Finance/Reconciliation?' + $.param(params), 'Download reconciliation');
        }, function (error) {
            $.bootstrapGrowl('Auth error: ' + error.message, {'delay':2000, 'type':'danger'});
            console.log('Auth error: ' + error.message);
        });
    }
}
}

function reporting_graphs_to_sql(graph) {
  //Show the query behind the graph
  if (graph in graph_sql) {
    $(document).on( "hashchanged", function(event) {
      reporting_query_load(graph_sql[graph]);
      $(this).unbind(event);
    });
    document.location.hash = 'reporting_query';

  } else {
    $.bootstrapGrowl('SQL not found', {'delay':2000, 'type':'danger'});
  }

}
