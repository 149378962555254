function filter_load() {
  filter_change_granularity();
}

function filter_change_granularity(show_granularity, show_driver_id, filter_button_name) {
  //Hide all time window selectors
  $("div[id^='filter_group_']").addClass('hidden');

  if (typeof filter_button_name === 'undefined') filter_button_name = 'Filter';
  $('#filter_button').html(filter_button_name);

  //determine selected granularity
  if (show_granularity) {
    //Show granularity input box
    $('#filter_granularity_div').removeClass('hidden');

    var value = $("#filter_granularity").val();
    if (value == 'hours') {
      selection_granularity = 'day'; //yyyy-mm-dd
      selection_default_from = moment().format('YYYY-MM-DD');
      selection_default_to = moment().format('YYYY-MM-DD');
    } else if (value == 'days') {
      selection_granularity = 'day'; //yyyy-mm-dd
      selection_default_from = moment().subtract(1,'months').format('YYYY-MM-DD');
      selection_default_to = moment().format('YYYY-MM-DD');
    } else if (value == 'weeks') {
      selection_granularity = 'week'; //yyyy-Www
      selection_default_from = moment().subtract(10,'weeks').format('GGGG-[W]WW'); //ISO Year + Week
      selection_default_to = moment().format('GGGG-[W]WW');
    } else if (value == 'months') {
      selection_granularity = 'month'; //yyyy-mm
      selection_default_from = moment().subtract(12,'months').format('YYYY-MM');
      selection_default_to = moment().format('YYYY-MM');
    } else {
      //years
      selection_granularity = 'year'; //yyyy
      selection_default_from = moment().subtract(1,'years').format('YYYY');
      selection_default_to = moment().format('YYYY');
    }
  } else {
    $('#filter_granularity_div').addClass('hidden');
    selection_granularity = 'day'; //yyyy-mm-dd
    selection_default_from = moment().subtract(1, 'months').format('YYYY-MM-DD');
    selection_default_to = moment().subtract(1, 'days').format('YYYY-MM-DD');
  }
  $('#filter_from_'+selection_granularity).val(selection_default_from);
  $('#filter_to_'+selection_granularity).val(selection_default_to);

  //show right timewindow selectors
  $("div[id^='filter_group_"+selection_granularity+"']").removeClass('hidden');
  if (show_driver_id) {
    $("div[id^='filter_group_driver_id']").removeClass('hidden');
  }
}

function filter_get() {
  var value = $("#filter_granularity").val();
  var driver_id = $("#filter_driver_id").val();
  if (driver_id == '') {
    driver_id = false;
  }
  var from_field_prefix = '#filter_from_';
  var to_field_prefix = '#filter_to_';

  if (value == 'hours') {
    first_period_start = moment($(from_field_prefix+'day').val(), "YYYY-MM-DD").startOf('day');
    last_period_start = moment($(to_field_prefix+'day').val(), "YYYY-MM-DD").startOf('day').add(23,'hours');
  } else if (value == 'days') {
    first_period_start = moment($(from_field_prefix+'day').val(),'YYYY-MM-DD').startOf('day');
    last_period_start = moment($(to_field_prefix+'day').val(),'YYYY-MM-DD').startOf('day');
  } else if (value == 'weeks') {
    first_period_start = moment($(from_field_prefix+'week').val(),'GGGG-[W]WW').startOf('isoWeek');
    last_period_start = moment($(to_field_prefix+'week').val(),'GGGG-[W]WW').startOf('isoWeek');
  } else if (value == 'months') {
    first_period_start = moment($(from_field_prefix+'month').val(),'YYYY-MM').startOf('month');
    last_period_start = moment($(to_field_prefix+'month').val(),'YYYY-MM').startOf('month');
  } else if (value == 'years') {
    first_period_start = moment($(from_field_prefix+'year').val(),'YYYY').startOf('year');
    last_period_start = moment($(to_field_prefix+'year').val(),'YYYY').startOf('year');
  }
  return {
      first_period_start: first_period_start,
      last_period_start: last_period_start,
      grouping: value,
      driver_id: driver_id
    };
}
