function lookup_load(menu, view, data){
  data.user = adminPanel.abelKit._admin.get();
  data.contracts = adminPanel.abelKit._admin.get('contracts').data;
  data.selectedContract = adminPanel.abelKit._admin.get('contracts').getActive();
  $("#content").html(Templates[menu+'_'+view](data));
  resetLinkListener();
  $('[data-toggle="tooltip"]').tooltip();

  if (typeof lookup_load_item_actions !== 'undefined') {
    lookup_load_item_actions(menu, view, data);
  }

  //Cath enter in form submission (if existing)
  if (typeof data.item !== 'undefined') {
    $('#'+menu+'_'+view+'_item_form').submit(function(e) {
      if (typeof data.item.id !== 'undefined') {
        //update
        lookup_post_item(menu, view, data.item.id);
      } else if (typeof data.item_id !== 'undefined') {
        //update
        lookup_post_item(menu, view, data.item_id);
      } else {
        //add
        lookup_post_item(menu, view, false);
      }
      return false;
    });
  } else {
    //Catch enter in typeahead (if existing)
    $('#'+menu+'_'+view+'_form').submit(function(e) {
      document.location.hash = menu+'_'+view+'/search/' + encodeURIComponent($('#'+view+'_search').val());
      return false;
    });
  }
  return true;
}


var lookupSearch = false;
function lookup_search_load_more(){
  $('#searchResultsLoadMoreButton').addClass('hidden');
  if (lookupSearch !== false) lookup_search(lookupSearch.menu, lookupSearch.view, lookupSearch.query, true);
}


function lookup_search(menu, view, query, append){
  if (typeof query !== 'undefined') {
    $('#'+view+'_search').val(query);
  }
  var path = view.underscoreToPathCase()+'/Search/'+encodeURI($('#'+view+'_search').val());
  if (typeof append !== 'undefined' && append) {
    path = path+'/next/'+lookupSearch.nextTime+'/'+lookupSearch.nextKey;
  } else {
    lookupSearch = false;
  }
  $.ajaxWrapper(
    path, //resource
    'GET', //type
    {}, //data,
    false, //notification
    {
      success: function(data){
        //Unhide result table


        if (!append) {
          $("#searchtable").html(Templates[menu+'_'+view+'_searchtable']({selectedContract: adminPanel.abelKit._admin.get('contracts').getActive(), results: data.return, pagination: data.pagination}));
        }

        if (typeof append !== 'undefined') {
          //Only apply for views with pagination

          lookupSearch = {
            nextTime: data.pagination.nextTime,
            nextKey: data.pagination.nextKey,
            menu: menu,
            view: view,
            query: query
          };
          $('#searchResultsTable').append(Templates[menu+'_'+view+'_searchresults']({selectedContract: adminPanel.abelKit._admin.get('contracts').getActive(), results: data.return}).trim());

          if (data.pagination.endReached) {
            $('#searchResultsLoadMoreButton').addClass('hidden');
          } else {
            $('#searchResultsLoadMoreButton').removeClass('hidden');
          }
        }

        resetLinkListener();
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        $('#searchtable').innerHTML = '';
      }
    } //ajax options
  );
}

function lookup_item(menu, view, item_id){
  if (!item_id) {
    var resource = view.underscoreToPathCase(); //resource
  } else {
    var resource = view.underscoreToPathCase()+'/'+item_id; //resource
  }

  $.ajaxWrapper(
    resource,
    'GET', //type
    {}, //data,
    true, //notification
    {
      success: function(data){
        lookup_load(menu, view,{item:data.return, item_id: item_id});
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        lookup_load(menu, view,{});
      }
    } //ajax options
  );
}

function lookup_post_item(menu, view, item_id) {
  if (!item_id) {
    var resource = view.underscoreToPathCase(); //resource
  } else {
    var resource = view.underscoreToPathCase()+'/'+item_id; //resource
  }

  var requestData = false;
  if (typeof lookup_post_item_prep_data !== 'undefined') {
    retval = lookup_post_item_prep_data(menu, view, item_id);
    if (retval) {
      requestData = retval.requestData;
      requestType = retval.requestType;
    }
  }
  if (!requestData) {
    requestData = $('#'+menu+'_'+view+'_item_form').serializeObject();
    requestType = 'POST';
  }

  $.ajaxWrapper(
    resource,
    requestType, //type
    requestData,
    true, //notification
    {
      success: function(data){
        if (typeof lookup_post_item_actions !== 'undefined') {
          lookup_post_item_actions(menu, view, $('#'+menu+'_'+view+'_item_form').serializeObject(), data);
        }
        if (!item_id && typeof data.Id !== 'undefined') {
          document.location.hash = menu+'_'+view+'/'+data.Id;
        } else if (!item_id) {
          lookup_item(menu, view, false);
        } else {
          lookup_item(menu, view, item_id);
        }
        $.bootstrapGrowl('Done.', {'delay':2000, 'type':'success'});
      }
    } //ajax options
  );
}

function lookup_item_action(menu, view, action, item_id, ask_confirmation, go_home) {
  lookup_subitem_action(menu, view, action, item_id, false, item_id, ask_confirmation, go_home);
}

function lookup_subitem_action(menu, view, action, item_id, subitem_name, subitem_id, ask_confirmation, go_home) {
  var data = false;
  if (typeof lookup_item_action_prep_data !== 'undefined') {
    data = lookup_item_action_prep_data(menu, view, action, item_id);
  }
  if (!data) data = {};

  if (!ask_confirmation || confirm("Are you sure?") == true) {
    if (subitem_name == false) {
      var subpath = '';
    } else {
      var subpath = subitem_name.underscoreToPathCase()+'/';
    }

    $.ajaxWrapper(
      view.underscoreToPathCase()+'/'+subpath+subitem_id+'/'+action, //resource
      'POST', //type
      data, //data,
      false, //notification
      {
        success: function(data){
          if (go_home == true) {
            document.location.hash = menu+'_'+view;
          } else if (go_home == false){
              //Could also be null, then do nothing
            lookup_item(menu, view, item_id);
          }
          $.bootstrapGrowl('Done.', {'delay':2000, 'type':'success'});
        }
      } //ajax options
    );
  } else {
    $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
  }
}

function lookup_item_add(menu, view) {
  lookup_load(menu, view,{item: {new: true}});
}
