lookup_post_item_actions = function(menu, view, request, response) {
  if (menu == 'settings' && view == 'profile') {
    adminPanel.abelKit._admin.data.timezone = request.Timezone;
  }
}

lookup_post_item_prep_data = function(menu, view, request) {
  if (menu == 'settings' && (view == 'apps' || view == 'mail')) {
      if (!window.FormData) {
        $.bootstrapGrowl('Your browser is outdated. Use chrome.', {'delay':2000, 'type':'danger'});
        return false;
      } else {
        return {
          requestData: new FormData($('#'+menu+'_'+view+'_item_form')[0]),
          requestType: 'formData'
        };
      }
  } else if (menu == 'settings' && view == 'templates') {
    var editor = ace.edit("template_editor");

    if (request.substring(0, request.length - 2) == 'invoice') {
      var requestData = {'Content': editor.getValue()};
      $("[id^=template_subject_]").each(function() {
        var template = $(this).attr('id').substring(17);
        requestData['Subject' + template.charAt(0).toUpperCase() + template.slice(1)] = $(this).val();
      });
      return {
        requestData: requestData,
        requestType: 'POST'
      };
    } else {
      return {
        requestData: {'Content': editor.getValue(), 'Subject': $('#template_subject').val()},
        requestType: 'POST'
      };
    }
  } else if (menu == 'settings' && view == 'payments') {
    var requestData = requestData = $('#'+menu+'_'+view+'_item_form').serializeObject();
    if ($('#defaultPOSPaymentmethod').prop('checked')) {
      requestData['defaultPOSPaymentmethod'] = true;
    } else {
      requestData['defaultPOSPaymentmethod'] = false;
    }

    if ($('#defaultCashPaymentmethod').prop('checked')) {
      requestData['defaultCashPaymentmethod'] = true;
    } else {
      requestData['defaultCashPaymentmethod'] = false;
    }

    if ($('#blockAndUnblockCustomersBasedOnInvoiceStatus').prop('checked')) {
      requestData['blockAndUnblockCustomersBasedOnInvoiceStatus'] = true;
    } else {
      requestData['blockAndUnblockCustomersBasedOnInvoiceStatus'] = false;
    }

    if (isNaN(parseInt($('#invoiceFirstReminderInterval').val()))) {
      requestData['invoiceFirstReminderInterval'] = false;
    }  else {
      requestData['invoiceFirstReminderInterval'] = parseInt($('#invoiceFirstReminderInterval').val());
    }

    if (isNaN(parseInt($('#invoiceSecondReminderInterval').val()))) {
      requestData['invoiceSecondReminderInterval'] = false;
    }  else {
      requestData['invoiceSecondReminderInterval'] = parseInt($('#invoiceSecondReminderInterval').val());
    }

    /*if (isNaN(parseInt($('#invoiceCollectionAgencyIntervalAfterSecondReminder').val()))) {
      requestData['invoiceCollectionAgencyIntervalAfterSecondReminder'] = false;
    }  else {
      requestData['invoiceCollectionAgencyIntervalAfterSecondReminder'] = parseInt($('#invoiceCollectionAgencyIntervalAfterSecondReminder').val());
    }*/



    requestData['availablePaymentMethods'] = [];
    if ($('#paymentMethodsAdyenCreditcard').prop('checked')) requestData['availablePaymentMethods'].push('adyen_creditcard');
    if ($('#paymentMethodsAdyenSepaDdIdeal').prop('checked')) requestData['availablePaymentMethods'].push('adyen_sepa_dd_ideal');
    if ($('#paymentMethodsBraintreePaypal').prop('checked')) requestData['availablePaymentMethods'].push('braintree_paypal');
    if ($('#paymentMethodsBraintreeCreditcard').prop('checked')) requestData['availablePaymentMethods'].push('braintree_creditcard');

    return {
      requestData: requestData,
      requestType: 'POST'
    };
  } else if (menu == 'lookup' && view == 'reconciliation_upload') {
      if (!window.FormData) {
        $.bootstrapGrowl('Your browser is outdated. Use chrome.', {'delay':2000, 'type':'danger'});
        return false;
      } else {
        return {
          requestData: new FormData($('#'+menu+'_'+view+'_item_form')[0]),
          requestType: 'formData'
        };
      }
  }
  return false;
}

lookup_item_action_prep_data = function(menu, view, action, item_id) {
  if (menu == 'settings' && view == 'templates' && action == 'SendTest') {
    var editor = ace.edit("template_editor");
    return {'Content': editor.getValue(), 'Subject': $('#template_subject').val()};
  }
  return false;
}

lookup_load_item_actions = function(menu, view, data) {
  if (menu == 'settings' && view == 'templates') {
    ace.config.set('basePath', '/js/3rdp/ace-src-min');

    var editor = ace.edit("template_editor");

    editor.setBehavioursEnabled(false); //No autocomplete on tags
    editor.getSession().setMode("ace/mode/twig");
    editor.getSession().setUseWorker(false);

    //Catch change in dropdown
    $('#settings_template_id').change(function() {
      document.location.hash = 'settings_templates/' + $('#settings_template_id').val();
    });
  }
}
