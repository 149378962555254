function lookup_promocode_load(data){
  $("#content").html(Templates['lookup_promocode'](data));
  lookup_promocode_show_linked_promocodes();
  resetLinkListener();
  $('[data-toggle="tooltip"]').tooltip();
  //Catch enter in typeahead
  $("#lookup_promocode_form").submit(function(e) {
    document.location.hash = 'lookup_promocode/search/' + encodeURIComponent($('#promocode_search').val());
    return false;
  });
  return true;
}

function lookup_promocode_replace(data) {
  //add or insert
  if (data.action == 'add') {
    $.ajaxWrapper(
      'Promocode/', //resource
      'POST', //type
      data, //data,
      false, //notification
      {
        success: function(data){
          //console.log(data);
          document.location.hash = 'lookup_promocode/' + data.return.promocode.Id;
          $.bootstrapGrowl('Saved.', {'delay':2000, 'type':'success'});
        }
      } //ajax options
    );
  } else {
    //update or remove
    $.ajaxWrapper(
      'Promocode/'+$('#promocode_id').val(), //resource
      'POST', //type
      data, //data,
      false, //notification
      {
        success: function(response_data){
          if(data.action == 'remove') {
            document.location.hash = 'lookup_promocode';
            $.bootstrapGrowl('Removed.', {'delay':2000, 'type':'success'});
          } else {
            $.bootstrapGrowl('Saved.', {'delay':2000, 'type':'success'});
            lookup_promocode_lookup($('#promocode_id').val());
          }
        }
      } //ajax options
    );
  }
}

function lookup_promocode_remove(id) {
  if (confirm("Are you sure?") == true) {
    data = promocode_get_data();
    data.Active = false;
    data.action = 'remove';
    lookup_promocode_replace(data);
  } else {
    $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
  }
}


function lookup_promocode_duplicate(id) {
  var duplicates = prompt("How many duplicates do you want? Note that you should save changes first.", "100");
  if (duplicates != null) {
    $.ajaxWrapper(
      'Promocode/Duplicate/'+id, //resource
      'POST', //type
      {duplicates: parseInt(duplicates)}, //data,
      false, //notification
      {
        success: function(response_data){
          $.bootstrapGrowl('Duplicates created. Check your email for the list of codes.', {'delay':2000, 'type':'success'});
        }
      } //ajax options
    );
  } else {
    $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
  }
}

function lookup_promocode_new() {
  //TODO: replace makeid() with a hashid, as this is lame and not unique at all

  $.ajaxWrapper(
    'Promocode/Productname', //resource
    'GET', //type
    {}, //data,
    true, //notification
    {
      success: function(data){
        lookup_promocode_load({
          promocode:{
            new:true,
            NamedPartOfDiscountName: makeid(5),
            Segment: "Zendesk",
            MaxUsage: 1,
            NewCustomersOnly: false,
            Json: {
              Discount:{
                Description: "Free ride from support",
                DiscountPercentage:100,
                MaxDiscountEuro: 50.00,
                ValidFrom: moment().toISOString(),
                ValidTil: moment().add(2,'months').toISOString(),
                'ValidFlexibilities':[
                  'HighFlexibility',
                  'NeutralFlexibility',
                  'LowFlexibility'
                ],
              }
            }
          },
          productName: data.return.productName
        });
        lookup_promocode_attach_listeners();
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        lookup_promocode_load({});
      }
    } //ajax options
  );
}

function lookup_promocode_attach_listeners(){
  //Listeners for update or add screen
  adminPanel.promocode();
  adminPanel.view.new();

  $("#promocode_to_date_radio").change(function() {
    if (!this.checked) {
      $('#promocode_to_date').val('');
    } else {
      $('#promocode_to_days').val('');
    }
  });

  $("#promocode_to_date").change(function() {
    if ($('#promocode_to_date').val() != '') {
      $('#promocode_to_date_radio').prop('checked', true);
      $('#promocode_to_days').val('');
    }
  });

  $("#promocode_to_days").change(function() {
    if ($('#promocode_to_days').val() != '') {
      $('#promocode_to_days_radio').prop('checked', true);
      $('#promocode_to_date').val('');
    }
  });

  $("#promocode_from_active").change(function() {
    if (!this.checked) {
      $('#promocode_from').val('');
    }
  });

  $("#promocode_from").change(function() {
    if ($('#promocode_from').val() == '') {
      $('#promocode_from_active').prop('checked', false);
    }
  });

  $("#promocode_from").blur(function() {
    var display_value = $('#promocode_from_route').val();

    if ($('#promocode_from_street_number').val() != ''){
      display_value += ' '+$('#promocode_from_street_number').val();
    }
    if ($('#promocode_from_locality').val() != ''){
      display_value += ', '+$('#promocode_from_locality').val();
    }
    $('#promocode_from').val(display_value);
  });

  $("#promocode_to").blur(function() {
    var display_value = $('#promocode_to_route').val();

    if ($('#promocode_to_street_number').val() != ''){
      display_value += ' '+$('#promocode_to_street_number').val();
    }
    if ($('#promocode_to_locality').val() != ''){
      display_value += ', '+$('#promocode_to_locality').val();
    }
    $('#promocode_to').val(display_value);
  });

  $("#promocode_to_active").change(function() {
    if (!this.checked) {
      $('#promocode_to').val('');
    }
  });

  $("#promocode_to").change(function() {
    if ($('#promocode_to').val() == '') {
      $('#promocode_to_active').prop('checked', false);
    }
  });

  $("#promocode_limited_use").change(function() {
    if (!this.checked) {
      $('#promocode_max_uses').val('');
    }
  });

  $("#promocode_max_uses").change(function() {
    if ($('#promocode_max_uses').val() == '') {
      $('#promocode_limited_use').prop('checked', false);
    } else {
      $('#promocode_limited_use').prop('checked', true);
    }
  });



  $("#promocode_form").submit(function(e) {
    data = promocode_get_data();
    lookup_promocode_replace(data);

    return false;
  });
}

function promocode_get_data() {
  if ($('#promocode_limited_use').prop('checked')) {
    var max_usage = parseInt($('#promocode_max_uses').val());
  } else {
    var max_usage = 0;
  }
  if ($('#promocode_new_customers').prop('checked')) {
    var new_customers_only = true;
  } else {
    var new_customers_only = false
  }

  var json = {
    'Discount':{
      'DiscountType': 'marketing',
      'Description': $('#promocode_description').val().trim(),
      'DiscountPercentage':Number($('#promocode_discount_perc').val()),
      'MaxDiscountEuro':Number($('#promocode_max_discount_eur').val()),
      'ValidFlexibilities':[]
    }
  };
  if ($('#promocode_to_date_radio').prop('checked')) {
    if ($('#promocode_to_date').val() != '') {
      json['Discount']['ValidTil'] = $('#promocode_to_date').val() + ' 00:00:00.000';
    }
  }
  if ($('#promocode_flex_high').prop('checked')) {
    json['Discount']['ValidFlexibilities'].push('HighFlexibility');
  }
  if ($('#promocode_flex_neutral').prop('checked')) {
    json['Discount']['ValidFlexibilities'].push('NeutralFlexibility');
  }
  if ($('#promocode_flex_low').prop('checked')) {
    json['Discount']['ValidFlexibilities'].push('LowFlexibility');
  }
  if ($('#promocode_from_day').val() != '') {
    json['Discount']['ValidFrom'] = $('#promocode_from_day').val() + ' 00:00:00.000';
  }
  if ($('#promocode_from_active').prop('checked')) {
    json['Discount']['OriginArea'] = {
      'Location':{
        'GeoType':'wgs84',
        'LocationType':'gpscoord',
        'Coord':{
          'Y': $('#promocode_from_lat').val(),
          'X':$('#promocode_from_lng').val()
        },
        'Street': $('#promocode_from_route').val(),
        'HouseNumber': $('#promocode_from_street_number').val(),
        'Place': $('#promocode_from_locality').val(),
        'Zip': $('#promocode_from_postal_code').val(),
        'CountryCode': $('#promocode_from_country').val()
      },
      'RadiusMeter':$('#promocode_from_range').val()
    };
  }
  if ($('#promocode_to_active').prop('checked')) {
    json['Discount']['DestinationArea'] = {
      'Location':{
        'GeoType':'wgs84',
        'LocationType':'gpscoord',
        'Coord':{
          'Y': $('#promocode_to_lat').val(),
          'X':$('#promocode_to_lng').val()
        },
        'Street': $('#promocode_to_route').val(),
        'HouseNumber': $('#promocode_to_street_number').val(),
        'Place': $('#promocode_to_locality').val(),
        'Zip': $('#promocode_to_postal_code').val(),
        'CountryCode': $('#promocode_to_country').val()
      },
      'RadiusMeter':$('#promocode_to_range').val()
    };
  }

  if ($('#promocode_will_be_invoiced').prop('checked')) {
    json['Discount']['WillBeInvoiced'] = true;
  } else {
    json['Discount']['WillBeInvoiced'] = false;
  }


  var data = {
    'Json': json,
    'DiscountName': $('#promocode_code').val().trim(), //productname will be added in the middleware
    'MaxUsage': max_usage,
    'NewCustomersOnly': new_customers_only,
    'Segment': $('#promocode_segment').val().trim(),
    'Active': true,
    'LinkedPromocodes': lookup_promocode_get_linked_promocodes()
  };

  if ($('#promocode_to_days_radio').prop('checked')) {
    if ($('#promocode_to_days').val() != '') {
      data.DaysValid = parseInt($('#promocode_to_days').val());
    }
  }

  if (typeof $('#promocode_id').val() === 'undefined') {
    data.action = 'add';
  } else {
    data.action = 'update';
  }

  return data;
}

function lookup_promocode_search(query){
  if (typeof query !== 'undefined') {
    $('#promocode_search').val(query);
  }
  $.ajaxWrapper(
    'Promocode/Search/'+encodeURI($('#promocode_search').val()), //resource
    'GET', //type
    {}, //data,
    false, //notification
    {
      success: function(data){
        //Unhide result table
        $("#searchtable").html(Templates['lookup_promocode_searchtable']({promocodes: data.return.promocodes}));
        resetLinkListener();
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        $('#searchtable').innerHTML = '';
      }
    } //ajax options
  );
}

function lookup_promocode_lookup(promocode_id){
  $.ajaxWrapper(
    'Promocode/'+promocode_id, //resource
    'GET', //type
    {}, //data,
    true, //notification
    {
      success: function(data){
        data.return.promocode.new = false;
        lookup_promocode_load({promocode:data.return.promocode, productName: data.return.productName});
        lookup_promocode_attach_listeners();
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        lookup_promocode_load({});
      }
    } //ajax options
  );
}

function makeid(idlength) {
    var text = "";
    var possible = "abcdefghijklmnopqrstuvwxyz0123456789";

    for( var i=0; i < idlength; i++ )
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

function lookup_promocode_add_linked_promocode() {
  var id = parseInt($('#promocode_linked_code_input').val());
  var linkedPromocodes = lookup_promocode_get_linked_promocodes();
  linkedPromocodes.push(id);
  lookup_promocode_set_linked_promocodes(linkedPromocodes);
  $('#promocode_linked_code_input').val('');
}

function lookup_promocode_get_linked_promocodes() {
  try {
    return JSON.parse($('#promocode_linked_codes').val());
  } catch(e) {
    return [];
  }
}

function lookup_promocode_set_linked_promocodes(linkedPromocodes) {
  $('#promocode_linked_codes').val(JSON.stringify(linkedPromocodes));
  lookup_promocode_show_linked_promocodes();
}

function lookup_promocode_remove_linked_promocode(id) {
  var linkedPromocodes = lookup_promocode_get_linked_promocodes();
  var index = linkedPromocodes.indexOf(id);
  if (index > -1) {
    linkedPromocodes.splice(index, 1);
}
  lookup_promocode_set_linked_promocodes(linkedPromocodes);
}

function lookup_promocode_show_linked_promocodes() {
  var linkedPromocodes = lookup_promocode_get_linked_promocodes();
  var content = '';
  if (linkedPromocodes.length != 0) {
    var content = '<br>Links:<br>';
    for(var i=0; i < linkedPromocodes.length; i++ ) {
      content = content + '• <a href="#lookup_promocode/'+linkedPromocodes[i]+'">'+linkedPromocodes[i]+'</a> <a href="javascript:lookup_promocode_remove_linked_promocode('+linkedPromocodes[i]+');"><span class="glyphicon glyphicon-remove" aria-hidden="true"></span></a><br>';
    }
  }
  $("#promocode_linked_codes_view").html(content);
}
