function lookup_customer_load(data) {
  data.selectedContract = adminPanel.abelKit._admin.get('contracts').getActive();
  $("#content").html(Templates['lookup_customer'](data));
  resetLinkListener();
  $('[data-toggle="tooltip"]').tooltip();

  //Catch enter in typeahead
  $("#lookup_customer_form").submit(function(e) {
    //lookup_customer_search();
    document.location.hash = 'lookup_customer/search/' + encodeURIComponent($('#customer_search').val());
    return false;
  });

  //Do typeahead
  /*$("#customer_search").bind('change paste keyup',function() {
    lookup_customer_search();
  });*/

  //Add or update customer
  $("#customer_form").submit(function(e) {
    var enabled = false;
    if ($('#customer_enabled').prop('checked')) {
      enabled = true;
    }
    var mailchimp = false;
    if ($('#customer_mailchimp').prop('checked')) {
      mailchimp = true;
    }

    var holdExportToCollectionAgencyUntilText = moment.tz($('#customer_hold_export_to_collection_agency_until').val(), 'YYYY-MM-DD', adminPanel.abelKit._admin.get('timezone'));
    var holdExportToCollectionAgencyUntil = '';
    if (holdExportToCollectionAgencyUntilText.isValid()) holdExportToCollectionAgencyUntil = holdExportToCollectionAgencyUntilText.toISOString();

    var data = {
      Customer: {
        FirstName: $('#customer_first_name').val().trim(),
        LastName: $('#customer_last_name').val().trim(),
        Tel: $('#customer_phone').val().trim(),
        Email: $('#customer_email').val().trim()
      },
      HoldExportToCollectionAgencyUntil: holdExportToCollectionAgencyUntil
    };
    if (typeof $('#customer_id').val() === 'undefined') {
      data.Language = $('#customer_language').val();
      data.Mailchimp = mailchimp;
    } else {
      data.Password = $('#customer_password').val();
      data.Enabled = enabled;
    }
    lookup_customer_replace(data);

    return false;
  });

    // Add customer attribute
    $("#customer_attributes_form").submit(function(e) {
        const customerId = $('#customer_id').val();
        const attribute = $('#new_attribute').val();

        lookup_customer_add_attribute(customerId, attribute);
        return false;
    });

    return true;
};

function lookup_customer_replace(data) {
  //add or insert
  if (typeof $('#customer_id').val() === 'undefined') {
    $.ajaxWrapper(
      'Customer/', //resource
      'POST', //type
      data, //data,
      false, //notification
      {
        success: function(data){
          //console.log(data);
          document.location.hash = 'lookup_customer/' + data.result.Customer.CustomerId;
          $.bootstrapGrowl('Saved.', {'delay':2000, 'type':'success'});
        }
      } //ajax options
    );
  } else {
    //update
    $.ajaxWrapper(
      'Customer/'+$('#customer_id').val(), //resource
      'POST', //type
      data, //data,
      false, //notification
      {
        success: function(data){
          $.bootstrapGrowl('Saved.', {'delay':2000, 'type':'success'});
          lookup_customer_lookup($('#customer_id').val());
        }
      } //ajax options
    );
  }
}

function lookup_customer_search(query){
  if (typeof query !== 'undefined') {
    $('#customer_search').val(query);
  }
  $.ajaxWrapper(
    'Customer/Search/'+encodeURI($('#customer_search').val()), //resource
    'GET', //type
    {}, //data,
    false, //notification
    {
      success: function(data){
        //Unhide result table
        $("#searchtable").html(Templates['lookup_customer_searchtable']({customers: data.return}));
        resetLinkListener();
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        $('#searchtable').innerHTML = '';
      }
    } //ajax options
  );
}

/**
 * Add an attribute to a specific
 * customer.
 *
 * @param customer_id {int}
 * @param attribute   {string}
 */
function lookup_customer_add_attribute(customer_id, attribute) {
    $.ajaxWrapper(
        'AddUserAttribute',
        'POST',
        {
            'CustomerId': customer_id,
            'Attribute' : attribute
        },
        true,
        {
            success: function(data){
                document.location.hash = 'lookup_customer/' + customer_id;
                $.bootstrapGrowl('Attribute "' + attribute + '" added.', {'delay':2000, 'type':'success'});
            },
            error: function(xhr, errorType, exception) {
                document.location.hash = 'lookup_customer/' + customer_id;
                $.bootstrapGrowl('Failed to add attribute.', {'delay':2000, 'type':'error'});
            }
        }
    );
}

/**
 * Remove an attribute from a
 * specific customer.
 *
 * @param customer_id {int}
 * @param attribute   {string}
 */
function lookup_customer_remove_attribute(customer_id, attribute) {
    $.ajaxWrapper(
        'RemoveUserAttribute',
        'POST',
        {
            'CustomerId': customer_id,
            'Attribute' : attribute
        },
        true,
        {
            success: function(data){
                document.location.hash = 'lookup_customer/' + customer_id;
                $.bootstrapGrowl('Attribute "' + attribute + '" removed.', {'delay':2000, 'type':'success'});
            },
            error: function(xhr, errorType, exception) {
                document.location.hash = 'lookup_customer/' + customer_id;
                $.bootstrapGrowl('Failed to remove attribute.', {'delay':2000, 'type':'error'});
            }
        }
    );
}

function lookup_customer_lookup(customer_id){
  $.ajaxWrapper(
    'Customer/'+customer_id, //resource
    'GET', //type
    {}, //data,
    true, //notification
    {
      success: function(data){
          const customer = data.return.customer;

          customer.attributes = {};
          if (customer.hasOwnProperty('senior') && customer.senior === "SENIOR") {
              delete customer.senior;
              customer.attributes.Senior = true;
          }

          // Unhide result table
          lookup_customer_load({customer: customer, middleware_server: adminPanel.abelKit.getServer()});
      },
      error: function(xhr, errorType, exception) {
        //Hide result table
        lookup_customer_load({});
      }
    } //ajax options
  );
}

function lookup_customer_action(customerid,action) {
  if (confirm("Are you sure?") == true) {
    $.ajaxWrapper(
        'Customer/'+customerid+'/'+action, //resource
        'POST', //type
        {}, //data,
        true, //notification
        {
          success: function(data){
            $.bootstrapGrowl('Done.', {'delay':2000, 'type':'success'});
            lookup_customer_lookup(customerid);
          }
        } //ajax options
      );
  } else {
    $.bootstrapGrowl('No changes made.', {'delay':2000, 'type':'info'});
  }
}
