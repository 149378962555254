//Requires plottable
var graph_components = [];

function graph_clear(target) {
  if (graph_components[target] != undefined) {
    graph_components[target].destroy();
    delete graph_components[target];
  }
  //$(target).html('');
}


/*
  Function to be called outside of this file. This function calls line or bar chart.
  Parameters:
  series: array in tabular form, row by row. First column is assumed to contain the xAxis values
  xAxisFormatter: function that can be called with a signle val passed as argument, returing a formatted xAxis value
  yAxisFormatter: function that can be called with a signle val passed as argument, returing a formatted yAxis value
  seriesNames: array of strings, on shorter that series has columns (no name for xAxis)
  graphType: line, clusteredBar or stackedBar
  svg: target svg to render the graph in
*/
function graph_plot_graph(series, xAxisFormatter, yAxisFormatter, seriesNames, graphType, svg) {
  if (graphType == 'line') {
    var graph_data = [];
    for (i = 1; i < series.length; i++) {
      var entry = {seriesName: seriesNames[i-1], seriesData: []};
      for (j = 0; j < series[i].length; j++) {
        entry.seriesData.push({
          x: series[0][j],
          y: series[i][j],
        });
      }
      graph_data.push(entry);
    }
    graph_line(graph_data, xAxisFormatter, yAxisFormatter, 'svg#'+svg);
  } else {
    //bar
    var graph_data = {series: [], xAxis: []};
    for (j = 0; j < series[0].length; j++) {
      graph_data.xAxis.push(xAxisFormatter(series[0][j]));
    }

    for (i = 1; i < series.length; i++) {
      var entry = {seriesName: seriesNames[i-1], seriesData: []};
      for (j = 0; j < series[i].length; j++) {
        if (isNaN(series[i][j])) {
          entry.seriesData.push(null); //A bar chart does not accept NaN values
        } else {
          entry.seriesData.push(series[i][j]);
        }
      }
      graph_data.series.push(entry);
    }

    if (graphType == 'clusteredBar') {
      var clustered = true;
    } else {
      var clustered = false;
    }
    graph_bar(graph_data, clustered, yAxisFormatter, 'svg#'+svg);
  }
}

function graph_bar(data, clustered, yAxisFormatter, target) {
  /*Data should be in the format, where elements in xAxis are unique and xAxis.length is equal to seriesData.length
    {
      series:
        [{
          seriesName: 'a',
          seriesData: [ {1, 3, 4 ,5]
        },
        {
          seriesName: 'b',
          seriesData: [ 1, 3, 4 ,5]
        }],
      xAxis: ['a','b','c','d']
    };
  */

  //Build legend
  var colorScale = new Plottable.Scales.Color();
  var legend = new Plottable.Components.Legend(colorScale);

  var seriesNames = [];
  data.series.forEach(function(series) {
    seriesNames.push(series.seriesName);
  });
  colorScale.domain(seriesNames);
  legend.xAlignment("center");
  legend.yAlignment("center");

  //Create graph
  var xScale = new Plottable.Scales.Category();
  var yScale = new Plottable.Scales.Linear();
  if (data.series.length == 1) {
    var plot = new Plottable.Plots.Bar();
  } else {
    if (clustered) {
      var plot = new Plottable.Plots.ClusteredBar();
    } else {
      var plot = new Plottable.Plots.StackedBar();
    }
  }
  plot.x(function(d, i, dataset) { return dataset.metadata().xAxis[i]; }, xScale);
  plot.y(function(d) { return d; }, yScale);
  plot.attr("fill", function(d, i, dataset) { return dataset.metadata().seriesName; }, colorScale);
  plot.animated(true);

  //Add data to graph
  data.series.forEach(function(series) {
    var dataSet = new Plottable.Dataset(series.seriesData, {seriesName: series.seriesName, xAxis: data.xAxis} );
    plot.addDataset(dataSet);
  });

  //Add axes
  var x_axis = new Plottable.Axes.Category(xScale, "bottom");
  var y_axis = new Plottable.Axes.Numeric(yScale, "left").formatter(yAxisFormatter);

  //Combine chart and legend in single graph
  if (data.series.length == 1) {
    //No legend
    var chart = new Plottable.Components.Table([[y_axis, plot],[null, x_axis]]);
  } else {
    var chart = new Plottable.Components.Table([[y_axis, plot, legend],[null, x_axis,null]]);
  }

  chart.renderTo(target);
  graph_tooltip(plot, yAxisFormatter, false);
  graph_components[target] = chart;

  window.addEventListener("resize", function() {
    chart.redraw();
  });
}

function graph_line(data, xAxisFormatter, yAxisFormatter, target) {
  /*Data should be in the format:
    [{
      seriesName: 'a',
      seriesData: [
        {x: 1318874398, y:  1},
        {x: 1318875398, y:  2},
        {x: 1318876398, y:  3},
        {x: 1318877398, y:  4}
      ]
    },
    {
      seriesName: 'b',
      seriesData: [
        {x: 1318844398, y:  4},
        {x: 1318884398, y:  3},
        {x: 1318894398, y:  2},
        {x: 1318824398, y:  1}
      ]
    }];
  */

  //Build legend
  var colorScale = new Plottable.Scales.Color();
  var legend = new Plottable.Components.Legend(colorScale);

  var seriesNames = [];
  data.forEach(function(series) {
    seriesNames.push(series.seriesName);
  });
  colorScale.domain(seriesNames);
  legend.xAlignment("center");
  legend.yAlignment("center");

  //Create graph
  //var xScale =  new Plottable.Scales.Time();
  var xScale =  new Plottable.Scales.Linear();
  var yScale = new Plottable.Scales.Linear();
  //yScale.domainMin(0);
  var line = new Plottable.Plots.Line();
  line.x(function(d) { return d.x; }, xScale);
  line.y(function(d) { return d.y; }, yScale);
  line.attr("stroke", function(d, i, dataset) { return dataset.metadata().seriesName; }, colorScale);
  line.animated(true);

  var scatter = new Plottable.Plots.Scatter();
  scatter.x(function(d) { return d.x; }, xScale);
  scatter.y(function(d) { return d.y; }, yScale);
  scatter.attr("fill", function(d, i, dataset) { return dataset.metadata().seriesName; }, colorScale);
  scatter.animated(true);

  //Add data to graph
  data.forEach(function(series) {
    var dataSet = new Plottable.Dataset(series.seriesData, {seriesName: series.seriesName} );
    line.addDataset(dataSet);
    scatter.addDataset(dataSet);
  });

  var plots = new Plottable.Components.Group([line, scatter]);

  //Add axes
  //var x_axis = new Plottable.Axes.Time(xScale, "bottom");
  var x_axis = new Plottable.Axes.Numeric(xScale, "bottom").formatter(xAxisFormatter);
  var y_axis = new Plottable.Axes.Numeric(yScale, "left").formatter(yAxisFormatter);

  //Combine chart and legend in single graph
  if (data.length == 1) {
    //No legend
    var chart = new Plottable.Components.Table([[y_axis, plots],[null, x_axis]]);
  } else {
    var chart = new Plottable.Components.Table([[y_axis, plots, legend],[null, x_axis,null]]);
  }

  chart.renderTo(target);
  graph_tooltip(scatter, yAxisFormatter, xAxisFormatter);
  graph_components[target] = chart;

  window.addEventListener("resize", function() {
    chart.redraw();
  });
}

function graph_tooltip(plot,yAxisFormatter,xAxisFormatter) {
  // Initializing tooltip anchor
  var tooltipAnchorSelection = plot.foreground().append("circle").attr({
    r: 3,
    opacity: 0
  });

  var tooltipAnchor = $(tooltipAnchorSelection.node());
  tooltipAnchor.tooltip({
    animation: false,
    container: "body",
    placement: "auto",
    title: "text",
    trigger: "manual"
  });

  // Setup Interaction.Pointer
  var pointer = new Plottable.Interactions.Pointer();
  pointer.onPointerMove(function(p) {
    var closest = plot.entityNearest(p);
    if (closest) {
      if (typeof xAxisFormatter == 'function') {
        //Line
        title = closest.dataset._metadata.seriesName + ': ' + yAxisFormatter(closest.datum.y) + ' (' + xAxisFormatter(closest.datum.x) + ')'
      } else {
        //Bar
        if (closest.datum == null) {
          title =  'No data available';
        } else {
          title = closest.dataset._metadata.seriesName + ': ' + yAxisFormatter(closest.datum);
        }
      }
      tooltipAnchor.attr({
        cx: closest.position.x,
        cy: closest.position.y,
        "data-original-title": title
      });
      tooltipAnchor.tooltip("show");
    }
  });

  pointer.onPointerExit(function() {
    tooltipAnchor.tooltip("hide");
  });

  pointer.attachTo(plot);
}

//Helper functions
function graph_make_time_grid_and_fill_with_data(data, grouping, start, end, seriesRowFormatter, seriesCount) {
  /*
  data: an array of rows
  grouping: a step-function that moment can use to increment (e.g. days, months) between start and end
  start: a moment
  end: a moment
  seriesRowFormatter: is passed an element from the data-array and is expected to return an array representing the columns.
    First column should contain the xAxis and should have values that are unix timestamps equal to that of start, end or increments in between
  seriesCount: number of series that we should render for (ex. xAxis)
  */

  //Create xAxis and empty series
  var series = [];
  for (i = 0; i < seriesCount +1; i++) {
    series[i] = [];
  }
  var step = moment.tz(start, adminPanel.abelKit._admin.get('timezone'));
  while (step.isSameOrBefore(end)) {
    series[0].push(step.unix());
    for (i = 1; i < seriesCount +1; i++) {
      series[i].push(0);
    }
    step.add(1,grouping);
  }

  //Fill series
  for (i = 0; i < data.length; i++) {
    row = seriesRowFormatter(data[i]);

    var pos = series[0].indexOf(row[0]);
    if (pos != -1) {
      for (j = 1; j < row.length; j++) {
        //skip first column, as it contains the x-axis
        series[j][pos] = row[j];
      }
    }
  }

  return series;
}


function graph_time_xaxis_for_grouping (grouping) {
  if (grouping == 'hours') {
    return function(val){return moment.unix(val).format('D-MMM HH[:00]')};
  } else if (grouping == 'days') {
    return function(val){return moment.unix(val).format('ddd D-MMM')};
  } else if (grouping == 'weeks') {
    return function(val){return moment.unix(val).format('W-YYYY')};
  } else if (grouping == 'months') {
    return function(val){return moment.unix(val).format('MMM-YYYY')};
  } else if (grouping == 'years') {
    return function(val){return moment.unix(val).format('YYYY')};
  }
  return false;
}
